export const additionalSections = [
    {
        id: 'ArmlessChair',
        img: '/images/assets/Sectional/ArmlessChair.png',
        title: 'Armless Chair',
        link: '',
        styleItem: '',
        disable: false,
        value: 'Armless Chair',
        count: 1
    },
    {
        id: 'LAFChair',
        img: "/images/assets/Sectional/LAFChair.png",
        title: 'LAF Chair',
        link: '',
        styleItem: '',
        disable: true,
        value: 'LAF Chair',
        count: 0
    },
    {
        id: 'RAFChair',
        img: '/images/assets/Sectional/RAFChair.png',
        title: 'RAF Chair',
        link: '',
        styleItem: '',
        disable: false,
        value: 'RAF Chair',
        count: 0 
    },
    {
        id: 'LAFCuddleChair',
        img: '/images/assets/Sectional/LAFCuddleChair.png',
        title: 'LAF Cuddle Chair',
        link: '',
        styleItem: '',
        disable: false,
        value: 'LAF Cuddle Chair',
        count: 1
    },
    {
        id: 'RAFCuddleChair',
        img: '/images/assets/Sectional/RAFCuddleChair.png',
        title: 'RAF Cuddle Chair',
        link: '/sectional',
        styleItem: '',
        disable: false,
        value: 'RAF Cuddle Chai',
        count: 1
    }, 
]