import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Collections } from "../../../utils/Constants/Collections";
import { configColectionT, configUI } from "../../../utils/Constants/configUI";
import { configurationClassicSofa } from "../../../utils/Constants/configurationClassicSofa";
import { Models } from "../../../utils/Constants/Models";
import {
  isSectionalConfigurator,
  setCameraPLayerInCenter,
} from "../../../utils/supportUtils";
import {
  changeCameraPosition,
  getAssetIdSceneFromItem,
  getCamQuaternion,
  getRotationObject,
  getTranslaTion,
  getTranslationObject,
  setConfiguration,
} from "../../../utils/threekit/threekitFunc";
import {
  getComponentIdUIFromNameThreekitAtribute,
  isSectionConfiguratorUI,
} from "../../selectors/ui.selector";
import { store } from "../../store";
import {
  cameraValuesT,
  cartInfoT,
  collectionsKeysT,
  collectionsT,
  componentsUIT,
  configUIT,
  configurationStep,
  errorsName,
  modalsName,
  modelPayloadUIType,
  objectActiveParamsT,
  ObjInfoModelsPriceT,
  objThreekitAtributesT,
  stepType,
  UIState,
  userTypeT,
} from "./ui.types";

const initialState: UIState = {
  activeStepUI: "collection", // ?????
  allThreekitAtributes: {},
  collection: {
    objListCollections: Collections,
    activeCollection: "sofa",
    objPageModels: Models,
  },
  configUI: {
    componentsUI: {
      objTabs: {},
      accordions: {},
    },
    objectActiveParams: {},
    objectDependensThreekitRelativeUI: {},
  },
  isHidePrice: false,
  userType: "retail",
  // userType: "wholesale",
  effectiveAccountId: "DEFAULT",
  // effectiveAccountId: "0018L000009W9zUQAS",
  // effectiveAccountId: undefined,
  // effectiveAccountId: "Base account: no price",
  actualUrlToSave: "",
  firstModel: ["threekitAtribute"],
  errors: {
    Remove: {
      isOpen: false,
      text: "Remove any unnecessary pieces to add the finished sectional to your Cart",
    },
    Add: {
      isOpen: false,
      text: "Add additional pieces to add finished sectional to your Cart",
    },
  },
  cameras: {
    arrCameras: [
      {
        label: "Front view",
        value: "Front",
      },
      {
        label: "Left view",
        value: "Left",
      },
      {
        label: "Back view",
        value: "Back",
      },
      {
        label: "Right view",
        value: "Right",
      },
    ],
    activeCamera: "Front",
  },
  loaders: {},
  loadConfig: {
    loadInit3kit: false,
    loadPlayer: false,
    loadData: true,
  },
  cartInfo: {
    products: [],
    shortCode: "",
    renderImgUrl: "",
    isActive: false,
  },
  settinsGroup: "create",

  // activeStep: 'build',
  // activeCamera: 'Right Side',
  // collectionsUI: Collections,
  // collections: [
  //   {
  //     name: 'Deleno',
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     threekitTag: 'Sectional',
  //   },
  // ],
  // activeCollection: '',
  // loadPlayer: false,
  models: [],
  // errors: {
  //   Remove: {
  //     isOpen: false,
  //     text: 'Remove any unnecessary pieces to add the finished sectional to your Cart',
  //   },
  //   Add: {
  //     isOpen: false,
  //     text: 'Add additional pieces to add finished sectional to your Cart',
  //   },
  // },
  // infoModelsPrice: {
  //   singleConfig: {
  //     price: 0,
  //     skuShort: '',
  //     skuFull: '',
  //   },
  //   sectionalConfig: {},
  // },
  infoModelsPrice: {},
  modals: {
    SaveConfig: { isOpen: false },
    PrintInfo: { isOpen: false },
    SectionalTutorial: { isOpen: false },
    GesturesTutorial: { isOpen: false },
    NotValidMesagge: { isOpen: false },
    RefreshConfiguratorMesagge: { isOpen: false },
    FullScreenPlayer: { isOpen: false },
    PillowMoveHint: { isOpen: false },
    MailSent: { isOpen: false },
    // PillowTutorial: { isOpen: false },
    // PillowMaterialTutorial: { isOpen: false },
  },
  activePillow: "",
  // sendingSku: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setInitialState: (state) => {
      window.constantsUI = {};
      state = initialState;
      // return initialState;
    },
    changeActiveStep: (state, action: PayloadAction<configurationStep>) => {
      state.activeStepUI = action.payload;
    },
    setActualUrlToSave: (state, action: PayloadAction<{ url: string }>) => {
      state.actualUrlToSave = action.payload.url;
    },
    changeActiveSide: (
      state,
      action: PayloadAction<{ side: cameraValuesT }>
    ) => {
      state.cameras.activeCamera = action.payload.side;
      const cameraNameThreekit = `Camera${action.payload.side}`;
      setConfiguration("activeCamera", cameraNameThreekit).then((e) => {
        setCameraPLayerInCenter();
      });
      const currentPosition = window.player.camera.getPosition();
      const currentQuaternion = window.player.camera.getQuaternion();

      // Start
      // window.player.scene.get({from: player.instanceId}) || window.player.scene.get()
      // plugs -> Proxy[0] -> asset -> assetId  ---- це assetId нашої сцени
      // window.player.scene.get({from: "8a27e6b9-0a80-4f80-8f33-ea3db80c5eca", name: "CameraFront"}) ---- тут можемо отримати дані про нашу камеру
      // https://community.threekit.com/hc/en-us/articles/4579306951707-Camera-Switch-Animation - це приклад того як перемістити сцену

      // Отримуємо assetId нашої сцени
      // const assetIdScene = getAssetIdSceneFromItem();

      // Шукаємо на вибраній сцені вибрану камеру з UI

      // const currentCamera = window.player.scene.get({from: assetIdScene, name: cameraNameThreekit});
      // const currentNull = window.player.scene.get({from: assetIdScene, name: 'CamersWrap'});

      // Визначаємо позицію обраної камери
      // const translationCamera = getTranslationObject(cameraNameThreekit, assetIdScene)

      // const cameraMainRotation = getRotationObject('Camera Main', assetIdScene)

      // Визначаємо позицію нуля камери
      //  const translationNull = getTranslationObject('CamersWrap', assetIdScene)

      // Визначаємо поворот обраної камери
      // const rotationCamera = getRotationObject(cameraNameThreekit, assetIdScene)

      // Визначаємо Quaternion для нововго положення камери
      // const camQuaternion = getCamQuaternion(translationNull, translationCamera);

      // const qqq = new window.player.THREE.Quaternion().setFromAxisAngle(new window.player.THREE.Vector3(0,1,0), Math.PI)

      // Переміщуемо перспективну камеру в позицію обраної камери
      // Повертаємо перспективну камеру в позицію обраної камери
      // window.player.camera.setPosition(translationCamera);
      // window.player.camera.setQuaternion(camQuaternion);

      // window.player.scene.set({
      //   from: assetIdScene,
      //   name: 'Camera Main',
      //   plug: "Transform",
      //   property: "rotation",
      // }, cameraMainRotation);

      //End

      // changeCameraPosition()
    },
    openCloseError: (state, action: PayloadAction<{ name: errorsName }>) => {
      const { name } = action.payload;
      state.errors[name].isOpen = !state.errors[name].isOpen;
    },
    openCloseModals: (
      state,
      action: PayloadAction<{ name: modalsName; data?: any }>
    ) => {
      const { name, data } = action.payload;
      state.modals[name].isOpen = !state.modals[name].isOpen;
      state.modals[name].data = data;
    },
    // showHidePriceInfo: (state) => {
    //   state.modals.Info.isOpen = !state.modals.Info.isOpen;
    // },

    // ------ Old reduser for models UI --------
    addModel: (state, action: PayloadAction<modelPayloadUIType[]>) => {
      // const { assetId,  start } = action.payload;

      action.payload.forEach(({ assetId, start }) => {
        const findModel = state.models.find(
          (model) => model.assetId === assetId
        );

        // if (state.models.length === 0 && start) {
        //   state.PriceInfo = {
        //     ...state.PriceInfo,
        //     //@ts-ignore
        //     selections: [...state.PriceInfo.selections, {  }],
        //     //@ts-ignore
        //     // summary: state.PriceInfo.summary + Number(price),
        //   };
        // } else {
        //   state.PriceInfo = {
        //     ...state.PriceInfo,
        //     //@ts-ignore
        //     selections: [...state.PriceInfo.selections, {  }],
        //     //@ts-ignore
        //     // summary: state.PriceInfo.summary + Number(price),
        //   };
        // }

        if (!findModel) {
          state.models = [...state.models, { assetId, count: 1 }];
        } else if (findModel && !start) {
          const filterModels = state.models.filter(
            (model) => model.assetId !== assetId
          );

          state.models = [
            ...filterModels,
            { assetId, count: findModel.count + 1 },
          ];
        }
      });

      // state.loadConfig.loadPlayer = false;
    },

    // ------ New reduser for models info UI -------
    addInfoModel: (state, action: PayloadAction<ObjInfoModelsPriceT>) => {
      const objModels = action.payload;

      state.infoModelsPrice = {
        ...state.infoModelsPrice,
        ...objModels,
      };
    },
    updateInfoHidePrice: (state, action: PayloadAction<boolean>) => {
      const isHidePrice: boolean = action.payload;
      state.isHidePrice = isHidePrice;
    },
    updateInfoModelPrice: (
      state,
      action: PayloadAction<ObjInfoModelsPriceT>
    ) => {
      const objModels = action.payload;

      state.infoModelsPrice = { ...objModels };
    },
    deleteModelUi: (state, action: PayloadAction<{ assetId: string }>) => {
      const { assetId } = action.payload;

      const findModel = state.models.find((model) => model.assetId === assetId);

      if (findModel) {
        const filterModels = state.models.filter(
          (model) => model.assetId !== assetId
        );

        state.models = [
          ...filterModels,
          { assetId, count: findModel.count - 1 },
        ];
      }
    },
    deleteAllModelUi: (state) => {
      state.models = [];
    },
    setActiveColection: (state, action: PayloadAction<collectionsKeysT>) => {
      state.collection.activeCollection = action.payload;
    },
    // setCollections: (state, action: PayloadAction<collectionsT[]>) => {
    //   state = {
    //     ...state,
    //     collections: [...state.collections, ...action.payload],
    //   };
    // },
    setNewUIState: (state, action: PayloadAction<UIState>) => {
      state = { ...state, ...action.payload };
    },
    setSavedConfiguration: (state, action: PayloadAction<any>) => {
      const payload = action.payload;
      state.allThreekitAtributes = payload["allThreekitAtributes"];
      state.configUI.objectActiveParams = payload["objectActiveParams"];
      state.models = payload["models"];
    },
    // changePlayerState: (state, action: PayloadAction<boolean>) => {
    //   state.loadConfig.loadPlayer = action.payload;
    // },
    changeLoadPlayerState: (state, action: PayloadAction<boolean>) => {
      state.loadConfig.loadPlayer = action.payload;
    },
    changeLoadInit3kitState: (state, action: PayloadAction<boolean>) => {
      state.loadConfig.loadInit3kit = action.payload;
    },
    changeComponentsUI: (
      state,
      action: PayloadAction<{ componentsUI: componentsUIT }>
    ) => {
      state.configUI.componentsUI = action.payload.componentsUI;
    },
    changeConfigUI: (state, action: PayloadAction<collectionsKeysT>) => {
      const currentCollection = action.payload as collectionsKeysT;
      const configCurrentCollection = configUI[
        currentCollection
      ] as configColectionT;
      state.configUI = {
        componentsUI: {
          objTabs: configCurrentCollection.componentsUI.objTabs,
          accordions: configCurrentCollection.componentsUI.accordions,
        },
        objectActiveParams: configCurrentCollection.objectActiveParams,
        objectDependensThreekitRelativeUI:
          configCurrentCollection.objectDependensThreekitRelativeUI,
      };
      // state.configUI.componentsUI = action.payload.componentsUI;
    },
    // updateSectionalAccordionByName: (
    //   state,
    //   action: PayloadAction<{ values: any; accordionName: string }>
    // ) => {
    //   // потім зробити правильний шлях пошуку потрібного акордіону і потрібної категорії
    //   const name = action.payload.accordionName;
    //   const values = action.payload.values;
    //   state.configUI.componentsUI.accordions[
    //     name
    //   ].data.embeddedView[0].data.values = values;
    // },
    // addMaterialPrice: (
    //   state,
    //   action: PayloadAction<{ fabric: string; material: string }>
    // ) => {
    //   const { fabric, material } = action.payload;

    //   //@ts-ignore
    //   const filetFabric = state.PriceInfo.fabric.filter(
    //     (f: any, key: number) => f.fabric && !f.fabric.includes(fabric)
    //   );

    //   state.PriceInfo = {
    //     ...state.PriceInfo,
    //     fabric: [
    //       //@ts-ignore
    //       ...[state.PriceInfo.fabric[0], ...filetFabric],
    //       { fabric, material },
    //     ],
    //   };
    // },
    updatePillowPriceList: (state, action: PayloadAction<any>) => {
      //@ts-ignore
      // const body = state.PriceInfo.fabric.filter((fabric) => {
      //   if (fabric.blockName || fabric.fabric === "Body") {
      //     return true;
      //   }
      //   return false;
      // });
      // state.PriceInfo.fabric = [...body, ...action.payload];
    },

    updateCartInfo: (state, action: PayloadAction<cartInfoT>) => {
      state.cartInfo = {
        ...state.cartInfo,
        ...action.payload,
      };
    },

    // changeSendingStatus: (state) => {
    //   state.sendingSku = !state.sendingSku;
    // },
    setAllThreekitAtributes: (state, action: PayloadAction<any>) => {
      const { arrAllThreekitAtributes, isNewObjectActiveParams = false } =
        action.payload;
      let objectActiveParamsCopy = JSON.parse(
        JSON.stringify(state.configUI.objectActiveParams)
      );
      let objAllThreekitAtributes;
      let obgForInitialition = [];
      if (isSectionalConfigurator() || isSectionConfiguratorUI({ ui: state })) {
        if (arrAllThreekitAtributes.length === undefined) {
          objAllThreekitAtributes = arrAllThreekitAtributes;
          Object.values(arrAllThreekitAtributes).forEach((value) => {
            obgForInitialition.push(value);
          });
        } else {
          objAllThreekitAtributes = arrAllThreekitAtributes.reduce(
            (accumulator: any, objAtribute: { name: any }) => {
              const { name } = objAtribute;
              return { ...accumulator, [name]: objAtribute };
            },
            {}
          );
          obgForInitialition = arrAllThreekitAtributes;
        }
      } else {
        objAllThreekitAtributes = arrAllThreekitAtributes.reduce(
          (accumulator: any, objAtribute: { name: any }) => {
            const { name } = objAtribute;
            return { ...accumulator, [name]: objAtribute };
          },
          {}
        );
        obgForInitialition = arrAllThreekitAtributes;
      }

      const objInitialActiveValuesUIFromThreekit = obgForInitialition.reduce(
        (
          accumulator: any,
          objAtribute: { name: any; value: any; values: any; type: any }
        ) => {
          const { name, value, values, type } = objAtribute;
          let valueForParameterUI: string | boolean = "";

          if (name === "Sofa type") return { ...accumulator };
          switch (type) {
            case "Asset":
              values.forEach((valueAttr: any) => {
                if (valueAttr["assetId"] === value["assetId"]) {
                  valueForParameterUI = valueAttr["name"];
                }
              });
              break;
            case "String":
            case "Boolean":
            case "Array":
              valueForParameterUI = value;
              break;
            default:
              valueForParameterUI = value;
          }

          if (name === "Materials" && !!!valueForParameterUI) {
            valueForParameterUI = "Malibu_Dove";
          }

          const componentIdUI = getComponentIdUIFromNameThreekitAtribute({
            ui: state,
          })(name);

          if (
            !isNewObjectActiveParams &&
            !!objectActiveParamsCopy[componentIdUI]
          ) {
            return { ...accumulator };
          }

          return { ...accumulator, [componentIdUI]: valueForParameterUI };
        },
        {}
      );
      console.log(
        "objInitialActiveValuesUIFromThreekit: ",
        objInitialActiveValuesUIFromThreekit
      );

      state.configUI.objectActiveParams = {
        ...objectActiveParamsCopy,
        ...objInitialActiveValuesUIFromThreekit,
      };

      state.allThreekitAtributes = objAllThreekitAtributes;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.configUI.objectActiveParams["activeTab"] = action.payload;
    },
    setActiveCategory: (state, action: PayloadAction<string>) => {
      state.configUI.objectActiveParams["activeCategory"] = action.payload;
    },
    setUserType: (state, action: PayloadAction<userTypeT>) => {
      state.userType = action.payload;
    },
    setEffectiveAccountId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.effectiveAccountId = action.payload;
    },
    setActiveParamsUI: (state, action: PayloadAction<objectActiveParamsT>) => {
      let objectActiveParamsCopy = JSON.parse(
        JSON.stringify(state.configUI.objectActiveParams)
      );
      const dataPayload = action.payload;

      // debugger;
      state.configUI.objectActiveParams = {
        ...objectActiveParamsCopy,
        ...dataPayload,
      };
    },
    setActivePillow: (state, action: PayloadAction<string>) => {
      state.activePillow = action.payload;
    },
  },
});

export const {
  setInitialState,
  changeActiveStep,
  openCloseError,
  openCloseModals,
  // showHidePriceInfo,
  addModel,
  addInfoModel,
  deleteModelUi,
  deleteAllModelUi,
  changeActiveSide,
  // addMaterialPrice,
  setNewUIState,
  changeLoadPlayerState,
  changeLoadInit3kitState,
  // changeSendingStatus,
  setActiveColection,
  setAllThreekitAtributes,
  setActiveTab,
  setActiveCategory,
  setActiveParamsUI,
  setUserType,
  setEffectiveAccountId,
  updateInfoHidePrice,
  updatePillowPriceList,
  changeComponentsUI,
  changeConfigUI,
  setSavedConfiguration,
  setActivePillow,
  updateInfoModelPrice,
  updateCartInfo,
  setActualUrlToSave,
} = uiSlice.actions;

export default uiSlice.reducer;
