import { SavedConfiguration } from "./../../api/api";
import { store } from "./../../redux/store";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { getObjectUniqueMaterialModels } from "../../utils/threekit/attributesFunctions";
import { THREEKIT_PARAMS } from "../../utils/constants";

export const getShortId = async ({
  allThreekitAtribute = undefined,
  objActiveParamsUI,
  modelsUI,
  pillows,
  threeKit,
}: any) => {
  let threekitAtribute = allThreekitAtribute;
  if (isSectionalConfigurator()) {
    await getObjectUniqueMaterialModels(store.getState()).then(
      (materialModels) => {
        threekitAtribute = materialModels;
      }
    );
  }

  let configThreekit = {
    authToken: THREEKIT_PARAMS.authToken,
    orgId: THREEKIT_PARAMS.orgId,
    threekitUrl: THREEKIT_PARAMS.threekitUrl,
    metadata: {
      objectActiveParams: objActiveParamsUI,
      allThreekitAtributes: threekitAtribute,
      models: modelsUI,
      pillows: pillows,
      threeKit: threeKit,
    },
  };

  return Promise.all([SavedConfiguration(configThreekit)])
    .then((threekitConfig: any) => {
      console.log("threekitConfig: ", threekitConfig);
      const shortId = threekitConfig[0]["shortId"];

      return Promise.all([shortId]);
    })

    .catch((error) => {
      console.log("error --- ==== ", error);
    });
};
