import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Print } from "../../assets/svgCompopnent/Print";
import { getShortId } from "../../function/base/getShortId";
import { openCloseModals } from "../../redux/features/ui/uiSlice";
import {
  getPillows,
  getPillowsPrice,
} from "../../redux/selectors/threekit.selectors";
import {
  checkIsHidePrice,
  getAllThreekitAtribute,
  getModelsPrice,
  getObjActiveParamsUI,
} from "../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import s from "./Cart.module.scss";
import { getActualLinkForSaveConfigurator } from "../../utils/supportUtils";
import { getChectIsUserRoolGuest } from "../../redux/selectors/users/selectore";

export const Cart = () => {
  const [btnLoad, setBtnLoad] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const objActiveParamsUI = useAppSelector((state) =>
    getObjActiveParamsUI(state)
  );
  const allThreekitAtribute = useAppSelector((state) =>
    getAllThreekitAtribute(state)
  );
  const threeKit = useAppSelector((state) => state.threeKit);
  const pillows = useAppSelector((state) => getPillows(state));
  const modelsUI = useAppSelector((state) => state.ui.models);
  const priceModels = useAppSelector((state) => getModelsPrice(state));
  const pricePillows = useAppSelector((state) => getPillowsPrice(state));
  const isHidePrice = useAppSelector((state) => checkIsHidePrice(state));
  const { pathname } = useLocation();
  const chectIsUserRoolGuest = useAppSelector(getChectIsUserRoolGuest);

  const saveConfiguration = async () => {
    if (btnLoad) return;
    setBtnLoad(true);
    getShortId({
      allThreekitAtribute,
      objActiveParamsUI,
      modelsUI,
      pillows,
      threeKit,
    }).then((shortId: any) => {
      const url = getActualLinkForSaveConfigurator();

      const linkText = `${url}?shortId=${shortId[0]}`;

      dispatch(
        openCloseModals({ name: "SaveConfig", data: { linkText: linkText } })
      );
      setBtnLoad(false);
    });
  };

  const openModalPrint: any = () => {
    dispatch(openCloseModals({ name: "PrintInfo", data: {} }));
  };

  return (
    <div className={s.interface_cart}>
      <button
        className={`${s.interface_cart_save} ${btnLoad ? s.loading : ""}`}
        onClick={() => saveConfiguration()}
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.3401 4.60999C20.8294 4.099 20.2229 3.69364 19.5555 3.41708C18.888 3.14052 18.1726 2.99817 17.4501 2.99817C16.7276 2.99817 16.0122 3.14052 15.3448 3.41708C14.6773 3.69364 14.0709 4.099 13.5601 4.60999L12.5001 5.66999L11.4401 4.60999C10.4084 3.5783 9.00915 2.9987 7.55012 2.9987C6.09109 2.9987 4.69181 3.5783 3.66012 4.60999C2.62843 5.64169 2.04883 7.04096 2.04883 8.49999C2.04883 9.95903 2.62843 11.3583 3.66012 12.39L4.72012 13.45L12.5001 21.23L20.2801 13.45L21.3401 12.39C21.8511 11.8792 22.2565 11.2728 22.533 10.6053C22.8096 9.93789 22.9519 9.22248 22.9519 8.49999C22.9519 7.77751 22.8096 7.0621 22.533 6.39464C22.2565 5.72718 21.8511 5.12075 21.3401 4.60999V4.60999Z"
            stroke="#B57345"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span>Save draft</span>
      </button>
      <button
        className={`${s.interface_cart_print} ${chectIsUserRoolGuest && s.hidePrice}`}
        onClick={() => openModalPrint()}
      >
        {!isHidePrice && !chectIsUserRoolGuest && <span>${priceModels + pricePillows}</span>}
        <Print />
        <span>Print</span>
      </button>
    </div>
  );
};
