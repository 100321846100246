import React, { useEffect } from "react";
import "./App.css";
import "./index.css";
import "antd/dist/antd.css";

import MainPage from "./pages/MainPage/MainPage";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PresetPage from "./pages/PresetPage/PresetPage";
import { PAGES } from "./utils/constants";
import LayoutConfigurator from "./pages/ConfiguratorLayout/ConfiguratorLayout";

import { BrowserRouter as Router } from "react-router-dom";
import {
  changeLoadPlayerState,
  setActualUrlToSave,
  setEffectiveAccountId,
  setUserType,
} from "./redux/features/ui/uiSlice";
import { useAppDispatch } from "./utils/hooks";
import {
  updateModelsPrice,
  updatePillowsPrice,
} from "./redux/actions/player.action";

declare global {
  interface Window {
    player: any;
    playerT: any;
    configurator: any;
    threekitPlayer: any;
    constantsUI: any;
  }
}

export const RouterComponent = ({ ...props }: any) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    window.addEventListener("message", retailModeToggleMessage, false);
    window.addEventListener("message", designLabInitMessage, false);
    window.addEventListener("message", changeLoadStatus, false);
    window.addEventListener("message", setUrlToSaveConfig, false);
    
    function setUrlToSaveConfig(event: any) {
      if (
        // event.origin === "http://localhost:3000" &&
        event.data.event_id === "setUrlToSaveConfig"
      ) {
        const location = event.data.location;
        dispatch(setActualUrlToSave({url: location}));
      }
    }
    function changeLoadStatus(event: any) {
      if (
        // event.origin === "http://localhost:3000" &&
        event.data.event_id === "changeLoadStatus"
      ) {
        const loader = event.data.loader;
        dispatch(changeLoadPlayerState(loader));
      }
    }

    function designLabInitMessage(event: any) {
      if (
        // event.origin === "http://localhost:3000" &&
        event.data.event_id === "designLabInit"
      ) {
        const userType = event.data.retailMode ? "retail" : "wholesale";
        const effectiveAccountId = event.data.effectiveAccountId
          ? event.data.effectiveAccountId
          : undefined;

        dispatch(setUserType(userType));
        dispatch(setEffectiveAccountId(effectiveAccountId));

        window.parent.postMessage(
          {
            event_id: "designLabInitStatus",
            received: true,
          },
          "*" //or "www.parentpage.com"
        );
      }
    }

    function retailModeToggleMessage(event: any) {
      if (
        // event.origin === "http://localhost:3000" &&
        event.data.event_id === "retailModeToggle"
      ) {
        const userType = event.data.retailMode ? "retail" : "wholesale";
        // const effectiveAccountId = undefined;
        dispatch(setUserType(userType));
        dispatch(updateModelsPrice());
        dispatch(updatePillowsPrice());

        // dispatch(setEffectiveAccountId(effectiveAccountId));
      }
    }

   

  }, []);

  return (
    <Router>
      <Routes>
        <Route path={PAGES.index} element={<MainPage />} />
        <Route path={PAGES.models} element={<LayoutConfigurator />}>
          <Route index element={<PresetPage />} />
          <Route path={PAGES.config} element={<></>} />
        </Route>
      </Routes>
    </Router>
  );
};
