import React from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { RouterComponent } from "./RouterComponent";
import { InfoHorizentalMode } from "./components/InfoHorizentalMode/InfoHorizentalMode";
import { redirectToSavedConfigurator } from "./function/base/redirectToSavedConfigurator";
declare global {
  interface Window {
    player: any;
    playerT: any;
    configurator: any;
    threekitPlayer: any;
    constantsUI: any;
  }
}
export const ConfiguratorApp = ({ ...props }: any) => {
  redirectToSavedConfigurator();
  return (
    <>
      <>
        <InfoHorizentalMode />
        <RouterComponent />
      </>
      {/* 
      <iframe
        ref={inputEl}
        title="3kit_iframe"
        className="3kit_iframe"
        id="3kit_iframe"
        src={`http://localhost:3000`}
        style={{ height: "100vh", width: "100vw" }}
        frameBorder="0"
      ></iframe> */}
    </>
  );
};
