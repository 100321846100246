import React from "react";
import s from "./StandartModelsListGroup.module.scss";
import { StandartOptionsWrapSlider } from "../StandartOptionsWrapSlider/StandartOptionsWrapSlider";
import { useLocation } from "react-router-dom";
import { CustomizedState } from "../Player/Player";
import { useAppSelector } from "../../utils/hooks";
import { getModelsGroupIdByCollectionId } from "../../redux/selectors/ui.selector";

export default function StandartModelsListGroup({ ...props }) {
  const { title, subtitle, values } = props;
  const isPresetsModels =
    title.includes("presets") || title.includes("bestsellers");
  const locationState = useLocation().state as CustomizedState;
  let getModelsGroupId = "AdditionalItems";

  if (locationState) {
    getModelsGroupId = useAppSelector((state) =>
      getModelsGroupIdByCollectionId(state)(locationState.collectionId)
    );
  }
  const isShowHideOptions = getModelsGroupId === "AdditionalItems";
  const itemsToShow = title.includes("bestsellers") ? 1 : 4;

  return (
    <div className={s.preElection}>
      <div className={s.title}>{title}</div>
      <div className={s.subtitle}>{subtitle}</div>
      <StandartOptionsWrapSlider
        values={values}
        presetModels={isPresetsModels}
        link={true}
        id={getModelsGroupId}
        isShowHideOptions={isShowHideOptions}
        itemsToShow={itemsToShow}
      />
    </div>
  );
}
