import React from "react";
import { getSavedConfiguration } from "../../api/api";
import { THREEKIT_PARAMS } from "../../utils/constants";

export const redirectToSavedConfigurator = async () => {
  const pathname = window.location.pathname;
  let search = window.location.search;
  let searchParams = new URLSearchParams(search);
  let shortId = searchParams.get("shortId");
  let isSavedConfigurator = shortId && !pathname.includes("config");
  if (isSavedConfigurator) {
    const savedConfiguration = await getSavedConfiguration({
      ...THREEKIT_PARAMS,
      configurationId: shortId,
    });
    let configPathname = savedConfiguration.metadata.pathname
      ? `${savedConfiguration.metadata.pathname}?shortId=${shortId}`
      : "/";
    window.location.href = configPathname;
  }
};
