import React, { useEffect, useMemo } from "react";
import { tabsCollectionT } from "../../redux/features/ui/ui.types";
import {
  setActiveCategory,
  setActiveTab,
} from "../../redux/features/ui/uiSlice";
import { getPointsShow } from "../../redux/selectors/points.selector";
import {
  getActiveCategory,
  getActiveTab,
  getCollectionCategoryes,
  getCollectionCategoryesByTab,
  getCollectionTabs,
} from "../../redux/selectors/ui.selector";
import { store } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { setModelVisibility } from "../../utils/threekit/threekitFunc";

import s from "./TabsConfigurationInterface.module.scss";

export const TabsConfigurationInterface = (props: any) => {
  const dispatch = useAppDispatch();

  // Мемоизация результатов выполнения селекторов
  const objTabs = useAppSelector(getCollectionTabs);
  const activeTab = useAppSelector(getActiveTab);
  const activeCategory = useAppSelector(getActiveCategory);
  const objCategoryes: any = useAppSelector(getCollectionCategoryes);

  useEffect(() => {
    const interval = setInterval(async () => {
      const state = store.getState();
      const objTabs: any = state.ui.configUI.componentsUI.objTabs;
      const keysObjTabs = Object.keys(objTabs);
      if (objTabs) {
        const tab = keysObjTabs[0];
        const category = objTabs[keysObjTabs[0]][0];
        clearInterval(interval);
        dispatch(setActiveTab(tab));
        dispatch(setActiveCategory(category));
      }
    }, 100);
  }, []);

  useEffect(() => {
    const state = store.getState();
    let pointsShow = getPointsShow(state);
    if (
      activeCategory &&
      isSectionalConfigurator() &&
      activeCategory === "accordionAdditionalSections"
    ) {
      pointsShow["showPoint"].forEach((point) => {
        if (point) {
          setModelVisibility(point, true);
        }
      });
      pointsShow["listNotVisible"].forEach((point) => {
        if (point) {
          setModelVisibility(point, true);
        }
      });
    } else if (activeCategory && isSectionalConfigurator()) {
      pointsShow["showPoint"].forEach((point) => {
        if (point) {
          setModelVisibility(point, false);
        }
      });
      pointsShow["listNotVisible"].forEach((point) => {
        if (point) {
          setModelVisibility(point, false);
        }
      });
    }
  }, [activeCategory]);

  return (
    <div className={s.interface_tab__block}>
      {objTabs.length > 0 &&
        objTabs.map((objTabKey: tabsCollectionT, index: number) => {
          const isActive = objTabKey === activeTab ? s.active : "";
          return (
            <div
              className={`${s.interface_tab} ${isActive} ${
                objTabs.length === 1 && s.interface_oneTab
              } interface_tab`}
              data-activeTab={objTabKey === activeTab}
              style={
                (activeTab === "Headboards Only" || activeTab === "Beds") &&
                !isActive
                  ? { color: "#E9E7DB", borderColor: "#E9E7DB" }
                  : {}
              }
              onClick={() => {
                if (activeTab !== "Headboards Only" && activeTab !== "Beds") {
                  dispatch(setActiveTab(objTabKey));
                  dispatch(setActiveCategory(objCategoryes[objTabKey][0]));
                }
              }}
              key={`${objTabKey}-${index}`}
            >
              {objTabKey}
            </div>
          );
        })}
    </div>
  );
};
