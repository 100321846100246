import React, { useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  checkIsHidePrice,
  getModalInfo,
  getModelsPrice,
} from "../../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import s from "./SaveConfig.module.scss";
import { openCloseModals } from "../../../redux/features/ui/uiSlice";
import { getSnapshot } from "../PrintInfo/PrintInfo";
import { getPillowsPrice } from "../../../redux/selectors/threekit.selectors";
import { sendMailInfo } from "../../../api";
import { instanceApiFunc } from "../../../api/instanceApi";
import { Close } from "../../../assets/svgCompopnent/Close";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ConfigForm } from "../EmailForm/ConfigForm";

export type infoUserT = {
  email: string[];
  name: string;
};

export default function ModalSaveConfig() {
  const copyedTextInput: any = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [copied, setCopied]: any = useState(false);

  const priceModels = useAppSelector((state) => getModelsPrice(state));
  const pricePillows = useAppSelector((state) => getPillowsPrice(state));
  const isHidePrice = useAppSelector((state) => checkIsHidePrice(state));
  const { isOpen, data }: any = useAppSelector((state) =>
    getModalInfo(state)("SaveConfig")
  );
  const [sendText, setSendText] = React.useState("Send");

  const sendEmailWithConfigurationInfo = async (info: infoUserT) => {
    dispatch(openCloseModals({ name: "MailSent", data: {} }));
    const name = info.name;
    const emails = info.email;
    let snapShot = await getSnapshot();
    let copyTextInput = copyedTextInput.current.value;
    const price = priceModels + pricePillows;
    emails.map((email: any) => {
      const formData = {
        name,
        email,
        image: "snapShot",
        link: copyTextInput,
        price: isHidePrice ? 0 : price,
      };

      const instanceApi = instanceApiFunc({
        authToken: process.env.REACT_APP_API_TOKEN || "",
        urlApi: process.env.REACT_APP_API_URL || "/api",
      });

      sendMailInfo(instanceApi, formData).then((resultUpdate: any) => {
        if (resultUpdate.Message == "OK") {
          setSendText("Sent");

          setTimeout(() => {
            dispatch(openCloseModals({ name: "MailSent", data: {} }));
            setSendText("Send");
            dispatch(openCloseModals({ name: "MailSent", data: {} }));
          }, 4000);
        }
      });
    });
  };
  const emailSchema = Yup.string().email("Invalid email format");

  const firstEmailSchema = emailSchema.required("Email is required");

  const any1: any = Yup.lazy((value: any, options: any) => {
    // валидация первого элемента массива
    if (options.path === "email[0]") return firstEmailSchema;

    // валидация всех остальных элементов массива
    return emailSchema;
  });

  const QuoteConfigSchema = Yup.object().shape({
    email: Yup.array().of(any1),
    name: Yup.string(),
  });

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.modalSaveConfig}>
            <div
              className={s.close}
              onClick={() => dispatch(openCloseModals({ name: "SaveConfig" }))}
            >
              <Close />
            </div>
            <h2 className={s.title}>Save Configuration link</h2>
            <p className={s.subTitle}>
              Send via email to save / share your current configuration
            </p>
            <div className={s.formWrap}>
              <Formik
                initialValues={{ email: [""], name: "" }}
                validationSchema={QuoteConfigSchema}
                onSubmit={(values) => {
                  sendEmailWithConfigurationInfo(values);
                }}
              >
                {({ errors, touched, values, handleChange }) => (
                  <ConfigForm errors={errors} />
                )}
              </Formik>
            </div>
            <p className={s.smallText}>
              Easily return to or share this configuration with the link below.
            </p>
            <div className={s.linkWrap}>
              <input
                ref={copyedTextInput}
                type="text"
                value={!!data ? data["linkText"] : ""}
                readOnly={true}
                className={s.linkText}
              />
              <CopyToClipboard
                text={data["linkText"]}
                onCopy={() => setCopied(true)}
              >
                <button className={s.btnCopyLink}>
                  <img src="/images/assets/svg/CopyTextIcon.svg" alt="" />
                  {!copied ? "Copy link" : "Link Copied"}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
