export const configurationParams = (requestData: any, activeTab: any) => {
  const arrayOfadditionalInfo = [
    requestData.depth,
    requestData.style,
    requestData.comfort,
    requestData.leg,
    requestData.fabric,
  ];
  let quantityPAram: number = 0;
  arrayOfadditionalInfo.map((item: any) => {
    if (item) {
      quantityPAram++;
    }
  });

  const additionalNames: any = {
    values: ["Gel Plush"],
    data: {
      "Gel Plush": "Pure Form",
    },
  };
  const comfort = additionalNames.values.includes(requestData.comfort)
    ? additionalNames.data[requestData.comfort]
    : requestData.comfort;


  let configurationInfo = [
    {
      name: "Depth",
      value: requestData.depth,
      quantity: quantityPAram,
    },
    {
      name: "Arms",
      value: requestData.style,
      quantity: quantityPAram,
    },
    {
      name: "Comfort",
      value: comfort,
      quantity: quantityPAram,
    },
    {
      name: "Legs",
      value: requestData.leg,
      quantity: quantityPAram,
    },
    {
      name: "Fabric",
      value: requestData.fabric,
      quantity: quantityPAram,
    },
  ];

  if (activeTab === "Headboards Only") {
    configurationInfo = [
      {
        name: "Headboard Shape",
        value: requestData.style,
        quantity: quantityPAram,
      },
      {
        name: "Height",
        value: requestData.height,
        quantity: quantityPAram,
      },
      {
        name: "Bed size",
        value: requestData.bed_size,
        quantity: quantityPAram,
      },
      {
        name: "Treatment",
        value: requestData.treatment,
        quantity: quantityPAram,
      },
      {
        name: "Fabric",
        value: requestData.fabric,
        quantity: quantityPAram,
      },
    ];
  } else if (activeTab === "Beds") {
    configurationInfo = [
      {
        name: "Headboard Shape",
        value: requestData.style,
        quantity: quantityPAram,
      },
      {
        name: "Height",
        value: requestData.height,
        quantity: quantityPAram,
      },
      {
        name: "Bed size",
        value: requestData.bed_size,
        quantity: quantityPAram,
      },
      {
        name: "Treatment",
        value: requestData.treatment,
        quantity: quantityPAram,
      },
      {
        name: "Storage",
        value: requestData.storage,
        quantity: quantityPAram,
      },
      {
        name: "Fabric",
        value: requestData.fabric,
        quantity: quantityPAram,
      },
    ];
  }

  return configurationInfo;
};
