import { Accordion } from './../../components/Accordion/Accordion';
import { configUI } from './../../utils/Constants/configUI';
import { Store } from 'redux';
import { getNewCoordinate } from '../../utils/configSofa/CoorditaneModels';
import {
  getTypeObject,
  getPositionCurrentObject,
  getSizeObject,
  getPositionCurrentObjectParams,
} from '../../utils/configSofa/turningUtils';
import {
  getSideBindPosition,
  getTypeThreekitModel,
  getParametersModelPosition,
  getSideBindObject,
  updateAngleModel,
  createNameModel,
  getIndexModel,
  getAngleCurrentModelFunc,
  getObjFilteredPayloadStartModels,
  checkIsSelectedModel,
} from '../../utils/configSofa/utilsFuncModel';
import {
  createPointsFirstObject,
  createPoint,
} from '../../utils/configSofa/utilsFuncPoint';
import { PAGES, THREEKIT_PARAMS, TYPE_REDUCER } from '../../utils/constants';
import {
  defaultRotation,
  defaultTransform,
  defaultTranslation,
} from '../../utils/constNewObject';
import {
  getNameForArmNotFirstWord,
  isSameWordsInStrings,
  isSectionalConfigurator,
  setCameraPLayerInCenter,
} from '../../utils/supportUtils';
import {
  changeArttributeForUpdateScene,
  getAllPillowsMaterials,
  getAttribute,
  getDisplayAttributes,
  getObjectUniqueMaterialModels,
  getPillowMaterialInModel,
  setUpdateAttribute,
  setUpdateAttributes,
  upadateDefaultValueCurrentPillowMaterial,
  updateMaterialInModel,
  updateMaterialPillow,
} from '../../utils/threekit/attributesFunctions';
import { checkIsLoadingPlayer } from '../../utils/threekit/checkIsLoadingPlayer';
import {
  setConfiguration,
  getBoundingBox,
  setTranslationId,
  setRotationId,
  setTranslationName,
  setModelVisibility,
  getTranslaTion,
  setModelsForSectionalConfigurator,
  getIndexSelectedPillow,
  getConfiguratorInstance,
} from '../../utils/threekit/threekitFunc';
import {
  addNodeModel,
  addNewNodePoint,
  deleteNode,
  addNewNodePillow,
} from '../../utils/threekit/threekitModels';
import {
  setActivePoint,
  setObjectModel,
  setUpdatePointsAndModels,
  setDeletePointsAndModels,
  setActiveModelInState,
  completeBuild,
  setAllMaterialsForPillows,
  deleteAllModels,
  setPresetModelsAndParams,
  updateModelsPrice,
  setInitThreekitStaet,
  addPillowInState,
  deletePillowInStateById,
  updateThreeekitPillow,
  deleteAllPillowsInState,
  changeMaterialPillow,
  addActiveThreeekitPillow,
  changeThreekitConfiguration,
  setPillowInfoInState,
} from '../actions/player.action';
import { modelPayloadUIType } from '../features/ui/ui.types';

import {
  addInfoModel,
  addModel,
  changeComponentsUI,
  changeLoadInit3kitState,
  changeLoadPlayerState,
  deleteAllModelUi,
  deleteModelUi,
  setActiveParamsUI,
  setActivePillow,
  setAllThreekitAtributes,
  setInitialState,
  updateInfoHidePrice,
  updateInfoModelPrice,
  updatePillowPriceList,
} from '../features/ui/uiSlice';

// import {
//   setActivePoint,
//   setObjectModel,
//   setUpdatePointsAndModels,
//   setDeletePointsAndModels,
//   addActiveModel,
// } from "../features/threekit/threekit.reducer";
import {
  checkIsEdgeElement,
  getNewNextKeyModel,
  getAngleCurrentModel,
  getAllFollowingModel,
  getInfoDeleteModel,
  getModificationModel,
  getAssetIdById,
  checkIsFirst,
  getActiveUserModel,
  getModels,
  getNameModelById,
  getSelectedModel,
} from '../selectors/model.selector';
import {
  getPointIndexPoint,
  getPositionModificationPoint,
  getAllFollowingPoints,
  getModificationPoints,
  getLastPoint,
  getListNewPoint,
  getPointsSelectModelID,
  getPointsShow,
} from '../selectors/points.selector';
import {
  getObjForUpdateThreekitFromNameParams,
  getPillowById,
  getPillows,
  getShowPillowsMaterial,
  getThreekitActivePillow,
  getThreekitDefaultValues,
  getThreekitModels,
  getThreekitObjMaterials,
} from '../selectors/threekit.selectors';
import {
  getActiveCategory,
  getActiveCollectionId,
  getActiveParamFromIdComponentUI,
  getActiveParamsById,
  getActivePillow,
  getEffectiveAccountId,
  getLoadInit3kit,
  getObjActiveParamsUI,
  getObjActiveParamsUIFromObjActiveParamsThreekit,
  getObjAssetIdFromThreekit,
  getObjectDependensThreekitRelativeUI,
  getObjNameThreekitAtributeComponentIdUI,
  getOptionsArrForArmSelectorByArmSelectorWidth,
  getUIModel,
  getUserType,
  getValueFromThreekit,
  getValuesFromIdAttribute,
  isSectionConfiguratorUI,
} from '../selectors/ui.selector';
import { RootState, setStoreInLocalStorage } from '../store';
import { boundingBoxT } from '../types/typeModels';
import {
  positionObjectT,
  positionFirst,
  modelsObject,
  positionOptions,
  getPositionModificationPointT,
  modelsT,
  typesPositionModelsT,
  startModelsT,
} from '../types/typeThreekitStateModal';
import {
  modelType,
  objSetConfigurationT,
} from '../features/threekit/threekit.types';
import {
  getNewValueForArmSelector,
  getObjForUpdateThreekit,
} from '../../utils/ui/utilsForData';

import {
  getAllObjectLocations,
  setRotationObject,
  setTransitionObject,
} from '../../utils/threekit/positionOrRotationObject';
import { getParamsForApiPriceInfo, updatePriceInfoModels } from '../../api';
import { instanceApiFunc } from '../../api/instanceApi';
import { PricesAndSkuResponseApiT } from '../../api/types/typesModelsPriceInfo';
import { outputDataResponcePrice } from '../../function/base/supportConsoleViewPriceDada';
import { onAnnotationChange } from '../../utils/threekit/annotationStyle';
import { setAllPlane } from '../../utils/threekit/pillows/planeFuncition';
import {
  getInfoPillowInStateByName,
  getInfoPillowByLastAssetId,
  updateAllPillowsMaterial,
  getPricePillow,
} from '../../utils/configSofa/utilsFuncPillow';
import { getMaxPillowCurentSofaRelativeThreekit } from '../../function/pillows/pilowThreekitFunc';
import { dimentionsSetup } from '../../function/dimentionsSetup/dimentionsSetup';

export const PILLOW_POSITION_Y = 0.48;

export const threekitMiddleware: any =
  (store: Store) => (next: any) => (action: any) => {
    switch (action.type) {
      case TYPE_REDUCER.SET_START_MODEL_OBJ: {
        const { models, activeParamsThreekit } = action.payload;
        const objStartModels = models;
        const state = store.getState();
        const curentModels = getModels(state);
        const isSelectedModel = checkIsSelectedModel(
          curentModels,
          objStartModels
        );
        if (isSelectedModel) {
          return store.dispatch(changeLoadPlayerState(false));
        }

        if (Object.keys(curentModels).length > 0) {
          store.dispatch(deleteAllModels());
        }
        store.dispatch(changeLoadPlayerState(true));

        const rotation = defaultRotation;
        const translation = defaultTranslation;

        const objFilteredPayloadModels =
          getObjFilteredPayloadStartModels(objStartModels);

        let resultObjModels = {};
        let resultArrModelsUI: modelPayloadUIType[] = [];
        let resultObjPoints: Record<string, any> = {};
        let firstObjModel = {};
        let prevObjModel = {};

        const callback = (positionModel: typesPositionModelsT) => {
          const objModels = objFilteredPayloadModels[
            positionModel
          ] as startModelsT;
          if (Object.keys(objModels).length === 0) return;

          if (Object.keys(firstObjModel).length > 0) {
            prevObjModel = firstObjModel;
          }

          return new Promise((res: any, rej: any) => {
            const callbackI = (keyModel: any, objModels: startModelsT) => {
              return new Promise(async (resI: any, rejI: any) => {
                const arrKeyModelSplit = keyModel.split('_');
                const indexModel = parseInt(
                  arrKeyModelSplit[arrKeyModelSplit.length - 1].replace(
                    /[^\d]/g,
                    ''
                  )
                );
                const assetId = objModels[keyModel];

                const id = addNodeModel(assetId, translation, rotation);

                const instanceId =
                  await window.playerT.api.player.getAssetInstance({
                    id: id,
                    plug: 'Null',
                    property: 'asset',
                  });
                // console.log("instanceId: ", instanceId);

                const nameModel = getNameModelById(state)(assetId);
                window.playerT
                  .getAssetInstance({ id: id, plug: 'Null', property: 'asset' })
                  .then(() => {
                    if (positionModel === 'first') {
                      const sideBindModel = getSideBindPosition(assetId);
                      const typeThreekitModel = getTypeThreekitModel(assetId);
                      let parametersModelPosition = getParametersModelPosition({
                        sideBind: sideBindModel,
                        id,
                        сoordinateSystem: defaultTranslation,
                      });
                      //@ts-ignore
                      const objectModel: modelsObject<positionFirst> = {
                        [keyModel]: {
                          angle: 0,
                          sideBind: sideBindModel,
                          isFirst: true,
                          position: 0,
                          assetId: assetId,
                          transform: {
                            translation: { x: 0, y: 0, z: 0 },
                            rotation: { x: 0, y: 0, z: 0 },
                          },
                          type: typeThreekitModel,
                          id: id,
                          name: nameModel,
                          parametersModelPosition: parametersModelPosition,
                        },
                      };

                      const objectPoints = createPointsFirstObject(
                        objectModel[keyModel]
                      );

                      firstObjModel = objectModel[keyModel];
                      prevObjModel = objectModel[keyModel];
                      resultObjModels = { ...resultObjModels, ...objectModel };
                      resultObjPoints = { ...resultObjPoints, ...objectPoints };
                      resultArrModelsUI.push({ assetId, start: true });
                    } else {
                      const angleCurrentModel: any = getAngleCurrentModelFunc({
                        prevModel: prevObjModel,
                        positionModificationPoint: positionModel,
                        typeCurrentModel: getTypeObject(
                          getSideBindPosition(assetId)
                        ),
                      });
                      const sideBindModel = getSideBindPosition(assetId);
                      const typeThreekitModel = getTypeThreekitModel(assetId);
                      let parametersModelPosition = getParametersModelPosition({
                        sideBind: sideBindModel,
                        id,
                        сoordinateSystem: defaultTranslation,
                      });
                      const positionCurrentObject =
                        getPositionCurrentObjectParams(
                          prevObjModel,
                          positionModel,
                          angleCurrentModel,
                          sideBindModel,
                          parametersModelPosition
                        );
                      //@ts-ignore
                      const objectModel: modelsObject<positionOptions> = {
                        [keyModel]: {
                          angle: angleCurrentModel,
                          sideBind: sideBindModel,
                          isFirst: false,
                          position: indexModel,
                          assetId: assetId,
                          transform: positionCurrentObject,
                          type: typeThreekitModel,
                          id: id,
                          name: nameModel,
                          parametersModelPosition: parametersModelPosition,
                        },
                      };
                      setTranslationId(
                        id,
                        positionCurrentObject['translation']
                      );
                      setRotationId(id, positionCurrentObject['rotation']);

                      let newObject = objectModel[keyModel];
                      let sideBindObject = getSideBindObject(
                        newObject,
                        positionModel
                      );
                      const objectNewPoint = createPoint({
                        object: newObject,
                        indexButton: indexModel,
                        positionModificationPoint: positionModel,
                        sideBind: sideBindObject,
                      });

                      prevObjModel = objectModel[keyModel];
                      resultObjModels = { ...resultObjModels, ...objectModel };
                      resultObjPoints = {
                        ...resultObjPoints,
                        ...objectNewPoint,
                      };
                      resultArrModelsUI.push({ assetId });
                    }

                    resI();
                  });
              });
            };

            (async () => {
              for (const item of Object.keys(objModels)) {
                await callbackI(item, objModels);
              }
              res();
            })();
          });
        };

        (async () => {
          const allCollectionsKeys = Object.keys(
            objFilteredPayloadModels
          ) as Array<keyof typeof objFilteredPayloadModels>;

          for (const item of allCollectionsKeys) {
            await callback(item);
          }
        })().then(() => {
          Object.keys(resultObjPoints).forEach(async (namePoint: any) => {
            const point = resultObjPoints[namePoint];
            addNewNodePoint(namePoint, point.translation);
          });

          store.dispatch(setActivePoint(resultObjPoints));
          store.dispatch(
            setPresetModelsAndParams({
              models: resultObjModels,
              activeParamsThreekit: activeParamsThreekit,
            })
          );

          setTimeout(() => {
            setModelsForSectionalConfigurator(
              Object.values(objStartModels)
            ).then((config) => {
              const storeJSON = {
                //@ts-ignore
                variant: config,
                metadata: store.getState(),
              };
              setStoreInLocalStorage(storeJSON);
            });
          }, 2000);

          store.dispatch(addModel(resultArrModelsUI));
        });

        break;
      }
      case TYPE_REDUCER.SET_MODEL: {
        const { assetId, name, sku, price } = action.payload;
        const state = store.getState();
        const newIndex = getPointIndexPoint(state);
        const positionModificationPoint = getPositionModificationPoint(state);
        const storeCopy = JSON.parse(JSON.stringify(store.getState()));
        const valuesCurrentDepth =
          getActiveParamFromIdComponentUI('depth')(storeCopy);
        const NotBasicDepth = valuesCurrentDepth !== 'Classic';
        if (!newIndex) return;
        const isLastModel = checkIsEdgeElement(state);
        const keyPosition: any = getNewNextKeyModel(state);
        const rotation = { ...defaultRotation };
        let translation = { ...defaultTranslation };

        //@ts-ignore
        async function setActualModelSettings(id: string) {
          try {
            let configId = window.player.instanceId;
            //@ts-ignore
            const parrentPlayer = window.parrentPlayer.id;
            if (parrentPlayer) configId = parrentPlayer;
            const instanceId = await window.playerT.api.player.getAssetInstance(
              { id: id, plug: 'Null', property: 'asset' }
            );
            const nameModel = getNameModelById(state)(assetId);

            let angleCurrentModel: any = getAngleCurrentModel(
              getTypeObject(getSideBindPosition(assetId))
            )(state);

            const sideBindModel = getSideBindPosition(assetId);
            const typeThreekitModel = getTypeThreekitModel(assetId);
            let parametersModelPosition = getParametersModelPosition({
              sideBind: sideBindModel,
              id,
              сoordinateSystem: defaultTranslation,
            });

            const positionCurrentObject = getPositionCurrentObject({
              parametersCurrentModelPosition: parametersModelPosition,
              sideBind: sideBindModel,
            })(state);

            setTranslationId(id, positionCurrentObject['translation']);
            setRotationId(id, positionCurrentObject['rotation']);

            let boundingBox: boundingBoxT = getBoundingBox(id);

            //@ts-ignore
            const object: modelsObject<positionFirst> = {
              [keyPosition]: {
                angle: angleCurrentModel,
                sideBind: sideBindModel,
                isFirst: false,
                position: newIndex,
                assetId: assetId,
                transform: positionCurrentObject,
                type: typeThreekitModel,
                id: id,
                name: nameModel,
                parametersModelPosition: {
                  ...parametersModelPosition,
                  boundingBox,
                },
              },
            };

            let newObject = object[keyPosition];
            let sideBindObject = getSideBindObject(
              newObject,
              positionModificationPoint
            );

            const objectNewPoint = createPoint({
              object: newObject,
              indexButton: newIndex,
              positionModificationPoint,
              sideBind: sideBindObject,
            });

            if (isLastModel) {
              const allFollowingObject = getAllFollowingModel(state);
              const allFollowingPoints = getAllFollowingPoints(state);

              let allModelsForCorditeConversion: any = {
                ...object,
                ...allFollowingObject,
              };
              let allPointsForCorditeConversion: any = {
                ...objectNewPoint,
                ...allFollowingPoints,
              };

              allModelsForCorditeConversion = updateAngleModel(
                allModelsForCorditeConversion,
                positionModificationPoint
              );

              Object.keys(allModelsForCorditeConversion).forEach(
                (nameKey: any) => {
                  let currentModel = allModelsForCorditeConversion[nameKey];
                  let currentModelPosition = currentModel['position'];
                  let prevModelPosition = currentModelPosition - 1;
                  const keyPrevPosition: any = createNameModel(
                    positionModificationPoint,
                    prevModelPosition
                  );
                  const prevModel =
                    allModelsForCorditeConversion[keyPrevPosition];

                  // debugger;
                  if (prevModel) {
                    // debugger;
                    const { newXPosition, newZPosition } = getNewCoordinate({
                      angle: currentModel['angle'],
                      typeDirection: positionModificationPoint,
                      typePrevModel: getTypeObject(prevModel['sideBind']),
                      typeCurrentModel: getTypeObject(currentModel['sideBind']),
                      prevModel: prevModel,
                      parametersNextModelPosition:
                        currentModel['parametersModelPosition'],
                    });
                    currentModel['transform']['translation'] = {
                      ...currentModel['transform']['translation'],
                      x: newXPosition,
                      z: newZPosition,
                    };

                    allModelsForCorditeConversion = {
                      ...allModelsForCorditeConversion,
                      [nameKey]: currentModel,
                    };
                  }
                }
              );

              Object.keys(allPointsForCorditeConversion).forEach(
                (nameKey: any) => {
                  let currentPoint = allPointsForCorditeConversion[nameKey];
                  let currentModelPosition = currentPoint['pointIndex'];
                  let positionModel = currentModelPosition - 1;

                  const keyPrevPosition = createNameModel(
                    positionModificationPoint,
                    positionModel
                  );
                  const newObject =
                    allModelsForCorditeConversion[keyPrevPosition];

                  if (newObject) {
                    let sideBindObject = getSideBindObject(
                      newObject,
                      positionModificationPoint
                    );
                    const objectNewPoint = createPoint({
                      object: newObject,
                      indexButton: positionModel,
                      positionModificationPoint,
                      sideBind: sideBindObject,
                    });

                    allPointsForCorditeConversion = Object.assign(
                      {},
                      allPointsForCorditeConversion,
                      objectNewPoint
                    );
                  }
                }
              );

              // debugger;
              Object.values(allModelsForCorditeConversion).forEach(
                (model: any) => {
                  setTranslationId(
                    model['id'],
                    model['transform']['translation']
                  );
                  setRotationId(model['id'], model['transform']['rotation']);
                }
              );

              if (Object.keys(allPointsForCorditeConversion).length > 0) {
                Object.keys(allPointsForCorditeConversion).forEach(
                  (namePoint: any) => {
                    const point = allPointsForCorditeConversion[namePoint];
                    const pointInPlayer = Object.keys(
                      //@ts-ignore
                      window.player.scene.getAll()
                    ).filter((item) => item.includes(namePoint));

                    if (pointInPlayer.length > 0) {
                      setTranslationName(namePoint, point['translation']);
                    } else {
                      addNewNodePoint(namePoint, point.translation);
                    }
                  }
                );
                // store.dispatch(setUpdatePointsAndModelsMiddleware());
              }

              store.dispatch(
                setUpdatePointsAndModels({
                  models: JSON.parse(
                    JSON.stringify(allModelsForCorditeConversion)
                  ),
                  points: JSON.parse(
                    JSON.stringify(allPointsForCorditeConversion)
                  ),
                })
              );
              store.dispatch(addModel([{ assetId, name, sku, price }]));
            } else {
              if (Object.keys(objectNewPoint).length > 0) {
                Object.keys(objectNewPoint).forEach((nameKey: any) => {
                  const point = objectNewPoint[nameKey];
                  addNewNodePoint(nameKey, point.translation);
                });
                //@ts-ignore
                store.dispatch(setActivePoint(objectNewPoint));
              }

              store.dispatch(setObjectModel(object));
              store.dispatch(addModel([{ assetId, name, sku, price }]));
              // store.dispatch(setObjectModelMiddleware());
            }

            if (NotBasicDepth) {
              window.configurator
                .setConfiguration({
                  'Seat depth': 'Classic',
                })
                .then(() => {
                  window.configurator.setConfiguration({
                    'Seat depth': valuesCurrentDepth,
                  });
                });
            }
            const objActiveParams = getObjActiveParamsUI(storeCopy);
            store.dispatch(
              changeThreekitConfiguration({
                fabric: objActiveParams.fabric,
              })
            );
            // const objActiveParams = getObjActiveParamsUI(storeCopy);
            // console.log("objActiveParams: ", objActiveParams.fabric);
            // //@ts-ignore
            // window.parrentPlayer.configurator
            //   .setConfiguration({
            //     "Materials": objActiveParams.fabric,
            //   })
          } catch (err) {
            console.log('error: ', err);
          }
        }

        setConfiguration('Models', [{ assetId }]).then(async () => {
          if (NotBasicDepth) {
            window.configurator
              .setConfiguration({
                'Seat depth': 'Classic',
              })
              .then(() => {
                const id = addNodeModel(assetId, translation, rotation);
                // loadModelWithNoBasicDepth({ id });

                setActualModelSettings(id);
              });
          } else {
            const id = addNodeModel(assetId, translation, rotation);
            setActualModelSettings(id);
          }
        });
        break;
      }
      case TYPE_REDUCER.DELETE_MODEL: {
        const { assetId, navigate } = action.payload;

        const state: RootState = store.getState();

        // getInfoDeleteModel - ця функція повертає список або позитивних моделей або негативних,
        // аналогічно повертає такий ж список з точками і сам напрямок (positive, negative)
        // де проходять зміни
        const infoDeleteModel = getInfoDeleteModel(state);

        // modelAsset - перевіряє чи інсує такий асседАйді
        const modelAsset = getAssetIdById(state, assetId);

        const modelData = getSelectedModel(state);

        //беремо подушки
        const pillows = getPillows(state);

        //беремо всі моделі
        const allModels = getModels(state);
        // isFirst - перевіряє чи видаляється саме перша модель
        const isFirst = checkIsFirst(state);

        //інформація про модель
        const currentModel = allModels[modelData.selectedKeyModel];
        const currentModelBoundingBox = getBoundingBox(currentModel.id);

        // deleteNode - видаляє модель з плеєра в 3kit
        deleteNode(assetId);

        if (Object.values(allModels).length === 1) {
          store.dispatch(setInitialState());
          store.dispatch(setInitThreekitStaet());

          const allPoints = getListNewPoint(state);
          Object.keys(allPoints).forEach((pointKey: any) => {
            deleteNode(pointKey);
          });
          navigate('/sectional', { state: { collectionId: 'sectional' } });
        }

        if (Object.keys(infoDeleteModel['newObjectModel']).length > 0) {
          // modification - напрям моделей та точок по якому будуть відбуватись зміни
          let modification = infoDeleteModel['modification'];
          // positionSelectionModel - позиція моделі, для нульового це 0, для позитивних і негативних починається від 1
          let positionSelectionModel =
            infoDeleteModel['positionSelectionModel'];
          // allModelsForCorditeConversion - моделі з конкретним напрямом які будуть змінюватись
          let allModelsForCorditeConversion = infoDeleteModel['newObjectModel'];
          // allPointsForCorditeConversion - точки з конкретним напрямом які будуть змінюватись
          let allPointsForCorditeConversion = infoDeleteModel['newObjectPoint'];
          // listsModel - список моделей в сторону яка буде змінюватись
          let listsModel: any;
          // listsOppositeModel - список моделей в протилежну сторону яка буде змінюватись
          let listsOppositeModel: any;
          // listsPoints - список точок в сторону яка буде змінюватись
          let listsPoints: any;
          // listsOppositePoints - список точок в протилежну сторону яка буде змінюватись
          let listsOppositePoints: any;
          // direction - напрям моделей та точок по якому будуть відбуватись зміни
          let direction: any = '';
          // oppositeDirection - протидежний напрям моделей та точок по якому будуть відбуватись зміни
          let oppositeDirection: any = '';
          // models - усі моделі які знаходяться в сховищі
          const models: any = state.threeKit.models;
          // points - усі точки які знаходяться в сховищі
          const points = state.threeKit.newPoints;
          if (isFirst) {
            // ця перевірка визначає де актуальний напрямок, а де протидежний
            if (models['pos_positive_1#']) {
              direction = 'positive';
              oppositeDirection = 'negative';
            } else if (models['pos_negative_1#']) {
              direction = 'negative';
              oppositeDirection = 'positive';
            }
            modification = direction;

            // getListsModel - ця функція повертає масив імен моделей для задананого напрямку
            const getListsModel = (direction: any) => {
              return Object.keys(models).filter((modelKey) =>
                modelKey.includes(direction)
              );
            };
            listsModel = getListsModel(direction);
            listsOppositeModel = getListsModel(oppositeDirection);

            // getListsPoints - ця функція повертає масив імен точок для задананого напрямку
            const getListsPoints = (direction: any) => {
              return Object.keys(points).filter((pointKey) =>
                pointKey.includes(direction)
              );
            };
            listsPoints = getListsPoints(direction);
            listsOppositePoints = getListsPoints(oppositeDirection);

            // ця функція повертає обьєкт в якому знаходиться моделі зміщені на -1, тобто 1моделей стає на місце нульової, 2модель на місце першої
            const getActualModels = ({
              models,
              listsModel,
              direction,
            }: any) => {
              let obg: any = {};
              // deleteModel = `pos_${direction}_${listsModel.length}#`;
              listsModel.map((item: any, counter: number) => {
                const modelKey: any = item;
                const prevModelKey: any = `pos_${direction}_${counter}#`;
                if (modelKey === `pos_${direction}_1#`) {
                  const model = JSON.parse(JSON.stringify(models[modelKey]));
                  // modelType = getTypeObject(model["sideBind"]);
                  obg['pos_first_0#'] = model;
                  obg['pos_first_0#'] = {
                    ...obg['pos_first_0#'],
                    position: 0,
                    isFirst: true,
                    angle: 0,
                    transform: defaultTransform,
                  };
                } else {
                  obg[prevModelKey] = JSON.parse(
                    JSON.stringify(models[modelKey])
                  );
                  obg[prevModelKey].position = counter;
                }
              });

              return obg;
            };
            allModelsForCorditeConversion = getActualModels({
              models,
              listsModel,
              direction,
            });

            // ця функція повертає обьєкт в якому знаходиться точки зміщені на -1, тобто 1моделей стає на місце нульової, 2модель на місце першої
            const getActualPoints = ({
              points,
              listsPoints,
              direction,
            }: any) => {
              let obg: any = {};
              const firstModel = JSON.parse(
                JSON.stringify(allModelsForCorditeConversion['pos_first_0#'])
              );
              const firstModelCoordinate = firstModel.parametersModelPosition;

              const modelType = getTypeObject(
                allModelsForCorditeConversion['pos_first_0#']['sideBind']
              );
              const firstModelBoundingBox = firstModelCoordinate.boundingBox;
              const segmentsModelBoundingBox =
                firstModelCoordinate.segmentsModel;

              let coordPoint: any;
              let coordOppositePoint: any;
              let coordOppositePointX: any;
              let actualCoordinateForFirstPoint;
              if (points[`point_${direction}_2#`]) {
                actualCoordinateForFirstPoint = JSON.parse(
                  JSON.stringify(points[`point_${direction}_2#`].translation)
                );
                coordOppositePointX = firstModelBoundingBox.min.x;
              } else {
                actualCoordinateForFirstPoint = { x: 0, y: 0, z: 0 };

                coordOppositePointX =
                  direction === 'positive'
                    ? firstModelBoundingBox.min.x
                    : firstModelBoundingBox.max.x;
              }

              const actualCoordinateForFirstOppositePoint = JSON.parse(
                JSON.stringify(points[`point_${direction}_1#`].translation)
              );

              if (modelType === 'corner') {
                coordPoint = {
                  x:
                    (segmentsModelBoundingBox['OminX'] +
                      segmentsModelBoundingBox['OmaxX']) /
                    2,
                  y: actualCoordinateForFirstPoint.y,
                  z: actualCoordinateForFirstPoint.z,
                };
                coordOppositePoint = {
                  x: coordOppositePointX,
                  y: actualCoordinateForFirstOppositePoint.y,
                  z: actualCoordinateForFirstOppositePoint.z,
                };
              } else if (modelType === 'line') {
                coordPoint = {
                  x: firstModelBoundingBox.max.x,
                  y: actualCoordinateForFirstPoint.y,
                  z:
                    (segmentsModelBoundingBox['OminZ'] +
                      segmentsModelBoundingBox['OmaxZ']) /
                    2,
                };
                coordOppositePoint = {
                  x: coordOppositePointX,
                  y: actualCoordinateForFirstOppositePoint.y,
                  z:
                    (segmentsModelBoundingBox['OminZ'] +
                      segmentsModelBoundingBox['OmaxZ']) /
                    2,
                };
              }

              listsPoints.map((item: any, counter: number) => {
                const modelKey: any = item;
                const prevModelKey: any = `point_${direction}_${counter}#`;
                if (modelKey === `point_${direction}_1#`) {
                  const firstModel = JSON.parse(
                    JSON.stringify(
                      allModelsForCorditeConversion['pos_first_0#']
                    )
                  );
                  const point = JSON.parse(JSON.stringify(points[modelKey]));
                  const actualSideBindDirection =
                    direction === 'positive' ? 'left' : 'right';

                  if (firstModel.sideBind.length !== 1) {
                    obg[`point_${direction}_1#`] = point;
                    obg[`point_${direction}_1#`].pointIndex = 1;
                    obg[`point_${direction}_1#`].translation = coordPoint;
                  }

                  if (obg[`point_${oppositeDirection}_1#`]) {
                    obg[`point_${oppositeDirection}_1#`] = JSON.parse(
                      JSON.stringify(points[`point_${oppositeDirection}_1#`])
                    );
                    obg[`point_${oppositeDirection}_1#`].translation =
                      coordOppositePoint;
                  } else {
                    obg[`point_${oppositeDirection}_1#`] = JSON.parse(
                      JSON.stringify(points[`point_${direction}_1#`])
                    );
                    obg[`point_${oppositeDirection}_1#`].translation =
                      coordOppositePoint;

                    if (
                      window.player.scene.get({ name: 'point_negative_1#' }) ===
                      undefined
                    ) {
                      addNewNodePoint(
                        `point_${oppositeDirection}_1#`,
                        coordOppositePoint
                      );
                    }
                  }
                } else if (counter >= 2 && counter < listsPoints.length) {
                  obg[prevModelKey] = JSON.parse(
                    JSON.stringify(points[modelKey])
                  );
                  obg[prevModelKey].pointIndex = counter;
                }
              });

              return obg;
            };

            const modelsKeys = Object.keys(models);
            if (modelsKeys.length === 2) {
              const objectPoints = createPointsFirstObject(
                allModelsForCorditeConversion['pos_first_0#']
              );
              allPointsForCorditeConversion = objectPoints;

              Object.keys(allPointsForCorditeConversion).forEach((key) => {
                if (window.player.scene.get({ name: key }) === undefined) {
                  addNewNodePoint(
                    key,
                    allPointsForCorditeConversion[key].translation
                  );
                }
              });
            } else {
              allPointsForCorditeConversion = getActualPoints({
                points,
                listsPoints,
                direction,
              });
            }
          }

          type updateSceneT = {
            allModels: modelsT;
            allPoints: any;
            modification: any;
          };

          // updateScene - загальна функція яка приймає обьєкти моделей і точок з актуальним напрямком, потім перебудовує координати цих елементів і записує в сховище
          const updateScene = ({
            allModels,
            allPoints,
            modification,
          }: updateSceneT) => {
            allModels = updateAngleModel(allModels, modification);

            // перерахунок координат моделі
            Object.keys(allModels).forEach((nameKey: any) => {
              let currentModel = allModels[nameKey];
              let currentModelPosition = currentModel['position'];
              let prevModelPosition = currentModelPosition - 1;
              let modificationPoint =
                prevModelPosition === 0 ? 'first' : modification;

              const keyPrevPosition = createNameModel(
                modificationPoint,
                prevModelPosition
              );
              const prevModel = allModels[keyPrevPosition];
              if (prevModel) {
                const { newXPosition, newZPosition } = getNewCoordinate({
                  angle: currentModel['angle'],
                  typeDirection: modification,
                  typePrevModel: getTypeObject(prevModel['sideBind']),
                  typeCurrentModel: getTypeObject(currentModel['sideBind']),
                  prevModel: prevModel,
                  parametersNextModelPosition:
                    currentModel['parametersModelPosition'],
                });
                currentModel['transform']['translation'] = {
                  ...currentModel['transform']['translation'],
                  x: newXPosition,
                  z: newZPosition,
                };

                allModels = {
                  ...allModels,
                  [nameKey]: currentModel,
                };
              }
            });

            // перерахунок координат точок
            Object.keys(allPoints).forEach((nameKey: any) => {
              let currentPoint = allPoints[nameKey];
              let currentModelPosition = currentPoint['pointIndex'];
              let positionModel = currentModelPosition - 1;
              let modificationPoint =
                positionModel === 0 ? 'first' : modification;
              const keyPrevPosition = createNameModel(
                modificationPoint,
                positionModel
              );
              const newObject = allModels[keyPrevPosition];

              if (newObject) {
                let sideBindObject = getSideBindObject(newObject, modification);
                const objectNewPoint = createPoint({
                  object: newObject,
                  indexButton: positionModel,
                  positionModificationPoint: modification,
                  sideBind: sideBindObject,
                });
                allPoints = Object.assign({}, allPoints, objectNewPoint);
              }
            });

            // встановалення точок і моделей в потрібні координати
            Object.values(allModels).forEach((model: any) => {
              setTranslationId(model['id'], model['transform']['translation']);
              setRotationId(model['id'], model['transform']['rotation']);
            });

            if (Object.keys(allPoints).length > 0) {
              Object.keys(allPoints).forEach((namePoint: any) => {
                const point = allPoints[namePoint];
                setTranslationName(namePoint, point['translation']);
              });

              let oldModels = getModificationModel(modification)(state);
              let oldPoints = getModificationPoints(modification)(state);
              // видалення непотрібних моделей
              let differenceModels;
              if (isFirst) {
                // differenceModels = [deleteModel];
                differenceModels = [listsModel[listsModel.length - 1]];
              } else {
                differenceModels = oldModels.filter((x) => {
                  const indexModel = getIndexModel(x, modification);
                  return (
                    !Object.keys(allModels).includes(x) &&
                    !x.includes('first') &&
                    indexModel >= positionSelectionModel
                  );
                });
              }

              // видалення непотрібних точок
              let differencePoints = oldPoints.filter((x) => {
                const indexModel = getIndexModel(x, modification);
                return (
                  !Object.keys(allPoints).includes(x) &&
                  indexModel > positionSelectionModel
                );
              });

              if (differencePoints.length > 0) {
                deleteNode(differencePoints[0]);
              }

              if (Object.values(allModels).length >= 1) {
                pillows.forEach((pillow: any) => {
                  const translationPillow = getTranslaTion(pillow.id);

                  if (
                    (translationPillow.x > currentModelBoundingBox.min.x &&
                      translationPillow.x < currentModelBoundingBox.max.x) ||
                    (translationPillow.x < currentModelBoundingBox.min.x &&
                      translationPillow.x > currentModelBoundingBox.max.x)
                  ) {
                    let prevModel = Object.values(allModels).find(
                      (m: any) => m.position === currentModel.position - 1
                    );

                    if (!prevModel) {
                      prevModel = Object.values(allModels).find(
                        (m: any) => m.position === currentModel.position + 1
                      );
                    }

                    if (prevModel) {
                      const depth = getActiveParamsById(state)('depth');
                      const numVar = depth === 'Lounge' ? 0.2 : 0.1;
                      setRotationId(pillow.id, prevModel.transform.rotation);
                      const prevModelTranslation = getTranslaTion(prevModel.id);

                      let xTranslation = prevModelTranslation.x;
                      let zTranslation = prevModelTranslation.z;
                      if (
                        prevModel.type === 'Corner' &&
                        prevModel.name === 'Corner Unit'
                      ) {
                        if (prevModel.angle === -270) {
                          zTranslation = prevModelTranslation.z + 0.2;
                        } else if (prevModel.angle === -180) {
                          xTranslation = prevModelTranslation.x + 0.2;
                        } else if (prevModel.angle === -90) {
                          zTranslation = prevModelTranslation.z - 0.2;
                        } else if (prevModel.angle === 0) {
                          xTranslation = prevModelTranslation.x - 0.2;
                        }
                      } else if (
                        prevModel.type === 'Corner' &&
                        prevModel.name === 'Cuddle Corner'
                      ) {
                        if (prevModel.angle === -270) {
                          zTranslation = prevModelTranslation.z + 0.2;
                          xTranslation = prevModelTranslation.x - numVar;
                        } else if (prevModel.angle === -180) {
                          xTranslation = prevModelTranslation.x + 0.2;
                          zTranslation = prevModelTranslation.z + numVar;
                        } else if (prevModel.angle === -90) {
                          zTranslation = prevModelTranslation.z - 0.2;
                          xTranslation = prevModelTranslation.x + numVar;
                        } else if (prevModel.angle === 0) {
                          xTranslation = prevModelTranslation.x - 0.2;
                          zTranslation = prevModelTranslation.z - numVar;
                        }
                      }
                      setTranslationId(pillow.id, {
                        x: xTranslation,
                        y: prevModelTranslation.y + PILLOW_POSITION_Y,
                        z: zTranslation,
                      });
                    }
                  } else {
                    Object.values(allModels).forEach((m) => {
                      const modelBoundingBox = getBoundingBox(m.id);

                      if (
                        (translationPillow.x > modelBoundingBox.min.x &&
                          translationPillow.x < modelBoundingBox.max.x) ||
                        (translationPillow.x < modelBoundingBox.min.x &&
                          translationPillow.x > modelBoundingBox.max.x)
                      ) {
                        setRotationId(pillow.id, m.transform.rotation);
                        const modelTranslation = getTranslaTion(m.id);
                        setTranslationId(pillow.id, {
                          x: modelTranslation.x,
                          y: modelTranslation.y + PILLOW_POSITION_Y,
                          z: modelTranslation.z,
                        });
                      }
                    });
                  }
                });
              }

              store.dispatch(
                setDeletePointsAndModels({
                  models: allModels,
                  points: allPoints,
                  differenceModels: differenceModels,
                  differencePoints: differencePoints,
                })
              );
            }
          };
          updateScene({
            allModels: allModelsForCorditeConversion,
            allPoints: allPointsForCorditeConversion,
            modification,
          });

          // getOppositeCoordsForPointsAndModels - проводиться додатковий обрахунок моделей і точок, але в противоположному напрямку для того аби оновити дані по координатах
          const getOppositeCoordsForPointsAndModels = () => {
            if (isFirst) {
              const getActualOppositeModels = () => {
                let obgOpposite: any = {};
                obgOpposite['pos_first_0#'] =
                  allModelsForCorditeConversion['pos_first_0#'];
                listsOppositeModel.map((item: any, counter: number) => {
                  obgOpposite[item] = JSON.parse(JSON.stringify(models[item]));
                });

                return obgOpposite;
              };

              const getActualOppositePoints = () => {
                let obgOpposite: any = {};
                listsOppositePoints.map((item: any, counter: number) => {
                  obgOpposite[item] = JSON.parse(JSON.stringify(points[item]));
                });

                return obgOpposite;
              };
              let actualOppositeModels = getActualOppositeModels();
              let actualOppositePoints = getActualOppositePoints();

              if (actualOppositeModels[`pos_${oppositeDirection}_1#`]) {
                updateScene({
                  allModels: actualOppositeModels,
                  allPoints: actualOppositePoints,
                  modification: oppositeDirection,
                });
              }
            }
          };
          getOppositeCoordsForPointsAndModels();
        }

        const name: any = getUIModel(state, modelAsset);

        if (name) {
          //@ts-ignore
          store.dispatch(deleteModelUi({ name }));
        }

        break;
      }
      case TYPE_REDUCER.DELETE_ALL_MODELS: {
        const state: RootState = store.getState();
        const allModels = getModels(state);
        const allPoints = getListNewPoint(state);

        Object.values(allModels).forEach((modelObj: any) => {
          const modelId = modelObj.id;
          deleteNode(modelId);
        });

        Object.keys(allPoints).forEach((pointKey: any) => {
          deleteNode(pointKey);
        });

        store.dispatch(deleteAllModelUi());

        break;
      }

      case TYPE_REDUCER.ADD_ACTIVE_MODEL: {
        const { id } = action.payload;
        // debugger;
        const state = store.getState();
        let showPoint: string[] = [];
        const lastPoint = getLastPoint(state);
        const activeCategory = getActiveCategory(state);
        const listPoints = Object.keys(getListNewPoint(state));
        showPoint = [...showPoint, ...lastPoint];
        if (id.length !== 0) {
          let pointSelectModelId = getPointsSelectModelID(id)(state);
          showPoint = [...showPoint, ...pointSelectModelId];
        }

        const listNotVisible = listPoints.filter(
          (point: string) => !showPoint.includes(point)
        );

        // if (activeCategory === "accordionAdditionalSections") {
        //   showPoint.forEach((point) => {
        //     setModelVisibility(point, true);
        //   });
        //   listNotVisible.forEach((point) => {
        //     setModelVisibility(point, false);
        //   });
        // } else {
        //   showPoint.forEach((point) => {
        //     setModelVisibility(point, true);
        //   });
        //   listNotVisible.forEach((point) => {
        //     setModelVisibility(point, true);
        //   });
        // }

        store.dispatch(setActiveModelInState({ id }));

        // debugger;
        break;
      }

      case TYPE_REDUCER.DELETE_PILLOW: {
        const ids = window.player.selectionSet.ids;

        const storeCopy = JSON.parse(JSON.stringify(store.getState()));

        const idValueParamUI = 'pillows';
        const nameThreekitAttribute = 'setPillows';

        const activePillow: any = storeCopy.ui.activePillow;

        const indexRemovedPillow = getIndexSelectedPillow();
        const arrValuesCurrentUI =
          getActiveParamFromIdComponentUI(idValueParamUI)(storeCopy);
        const arrAttributeValuesCurrent = getAttribute(nameThreekitAttribute);
        let actualIndex = indexRemovedPillow;
        const pillows = getPillows(store.getState());
        let idActivePillow = '';

        if (isSectionalConfigurator()) {
          let seletPillowId = '';

          if (window.player.selectionSet.ids.length > 0) {
            seletPillowId = window.player.selectionSet.ids[0];
          } else {
            const filterForPillowByName = pillows
              .filter((pillow) => pillow.id.includes(action.payload))
              //@ts-ignore
              .at(-1);

            if (filterForPillowByName) {
              seletPillowId = filterForPillowByName.id;
            }
          }
          deleteNode(seletPillowId);
          idActivePillow = seletPillowId;
          // store.dispatch(deletePillowThreekit({ id: seletPillowId }));

          window.player.selectionSet.clear();
        }

        if (activePillow && !indexRemovedPillow && !isSectionalConfigurator()) {
          // const { id, namePillow, indexActivePillow } =
          // getInfoPillowByLastAssetId(assetId);

          const pillow: any = getInfoPillowInStateByName(activePillow);

          let countOfIndex = 0;
          let activeIndex = 0;

          const objpillows = storeCopy.ui.configUI.objectActiveParams.pillows;
          objpillows.map((item: any) => {
            countOfIndex++;
            if (item === activePillow) {
              activeIndex = countOfIndex;
            }
          });

          let id = [pillow.id];
          if (isSectionalConfigurator()) {
            id = window.player.scene.find({
              from: window.player.player.api.instanceId,
              hierarchical: true,
            });
          } else {
            activeIndex = pillow.indexPillow + 1;
          }

          if (id) {
            window.player.selectionSet.set(id[0]);
            actualIndex = activeIndex;
            store.dispatch(deletePillowInStateById({ id: id[0], pillows }));
          }
        } else if (!isSectionalConfigurator()) {
          const selectionId = window.player.selectionSet.ids;
          store.dispatch(
            deletePillowInStateById({ id: selectionId[0], pillows })
          );
        }

        let arrValuesUpdatedUI = arrValuesCurrentUI.filter(
          (_: any, index: number) => actualIndex !== index + 1
        );

        if (isSectionalConfigurator()) {
          arrValuesUpdatedUI = arrValuesCurrentUI.filter(
            (_: any, index: number) => actualIndex !== index
          );
        }
        let arrAttributeValuesUpdated = arrAttributeValuesCurrent.filter(
          (_: any, index: number) => actualIndex !== index + 1
        );
        if (isSectionalConfigurator()) {
          arrAttributeValuesUpdated = arrAttributeValuesCurrent.filter(
            (_: any, index: number) => actualIndex !== index
          );
        }

        if (!!actualIndex) {
          getAllObjectLocations(
            arrValuesCurrentUI,
            [actualIndex],
            arrAttributeValuesCurrent
          ).then(({ objectMeshPoint, instanceIdLayoutContainer }: any) => {
            Object.keys(objectMeshPoint).forEach((namePointInModel) => {
              let rotation = objectMeshPoint[namePointInModel]['rotation'];
              let transition = objectMeshPoint[namePointInModel]['transition'];
              if (rotation) {
                setRotationObject(
                  instanceIdLayoutContainer,
                  namePointInModel,
                  rotation,
                  window.playerT.api
                );
              }

              if (transition) {
                setTransitionObject(
                  instanceIdLayoutContainer,
                  namePointInModel,
                  transition,
                  window.playerT.api
                );
              }
            });
          });
        }
        setUpdateAttributes({
          [nameThreekitAttribute]: arrAttributeValuesUpdated,
        }).then(async (res) => {
          window.playerT.api.selectionSet.clear();

          if (!isSectionalConfigurator()) {
            store.dispatch(setActivePillow(''));
            store.dispatch(addActiveThreeekitPillow({ id: '' }));

            store.dispatch(changeLoadPlayerState(false));
            updateAllPillowsMaterial({});
          }
        });

        if (isSectionalConfigurator() && idValueParamUI === 'pillows') {
          const pillowIndex = pillows.findIndex((p) => p.id === idActivePillow);
          arrValuesUpdatedUI = arrValuesUpdatedUI.filter(
            (value: string, index: number) => index !== pillowIndex
          );
          arrAttributeValuesUpdated = arrAttributeValuesCurrent.filter(
            (_: any, index: number) => actualIndex !== index
          );

          if (activePillow && !indexRemovedPillow) {
            const state = store.getState();
            const pillow: any = getPillowById(state)(ids[0]);

            arrValuesUpdatedUI = [];
            let isDelete = false;
            arrValuesCurrentUI.map((item: any) => {
              if (isDelete || pillow.name !== item) {
                arrValuesUpdatedUI.push(item);
              } else if (pillow.name === item) {
                isDelete = true;
              }
            });
            store.dispatch(deletePillowInStateById({ id: pillow.id, pillows }));
            store.dispatch(setActivePillow(''));
            store.dispatch(addActiveThreeekitPillow({ id: '' }));
          }
        }

        return store.dispatch(
          setActiveParamsUI({ [idValueParamUI]: arrValuesUpdatedUI })
        );
      }

      case TYPE_REDUCER.DELETE_ALL_PILLOWS: {
        const storeCopy: RootState = JSON.parse(
          JSON.stringify(store.getState())
        );
        const idValueParamUI = 'pillows';
        const nameThreekitAttribute = 'setPillows';
        const pillows = getPillows(store.getState());

        if (isSectionalConfigurator()) {
          pillows.map((pillow: any) => {
            deleteNode(pillow.id);
          });
        }

        setUpdateAttributes({
          [nameThreekitAttribute]: [],
        }).then(async (res) => {
          window.playerT.api.selectionSet.clear();

          store.dispatch(deleteAllPillowsInState());
          store.dispatch(setActivePillow(''));
          store.dispatch(addActiveThreeekitPillow({ id: '' }));

          store.dispatch(setActiveParamsUI({ [idValueParamUI]: [] }));
        });

        break;
      }

      case TYPE_REDUCER.ADD_ACTIVE_PILLOW: {
        const storeCopy: RootState = JSON.parse(
          JSON.stringify(store.getState())
        );

        const activePillow = storeCopy.ui.activePillow;
        if (activePillow) {
          const interval = setInterval(async () => {
            const ids = window.player.selectionSet.ids[0];
            if (!!ids) {
              clearInterval(interval);
              const material: any = await getPillowMaterialInModel(ids);
              const defaultPillowMaterialName = material[0];

              const activeMaterialInfo = material[1].values.find(
                (item: any) => item.name === material[0]
              );
              const pillowMaterialObg = {
                ...material[2],
                value: { assetId: activeMaterialInfo.assetId },
                values: material[1].values,
              };
              const sceneAttributes = getDisplayAttributes();
              const allAttributes = [pillowMaterialObg, ...sceneAttributes];
              store.dispatch(
                setAllThreekitAtributes({
                  arrAllThreekitAtributes: allAttributes,
                })
              );
            }
          });
        }

        break;
      }

      case TYPE_REDUCER.DELETE_PILLOW_STATE: {
        const { id, pillows } = action.payload;

        const newArrPillows: any[] = [];

        const filterPillows = pillows.filter((item: any) => {
          return item.id !== id;
        });
        filterPillows.map((item: any, index: number) => {
          let actualId, materialObg;
          if (isSectionalConfigurator()) {
            actualId = item.id;
          } else {
            actualId = pillows[index].id;
            materialObg = pillows[index].materialObg;
          }
          newArrPillows.push({
            ...item,
            id: actualId,
            transition: item.transition,
            name: item.name,
            materialObg: item.materialObg,
            indexPillow: index,
          });
        });

        if (!isSectionalConfigurator()) {
          store.dispatch(changeLoadPlayerState(true));

          setTimeout(() => {
            updateMaterialPillow(newArrPillows).then(async () => {
              store.dispatch(changeLoadPlayerState(false));
            });
          }, 0);
        }

        store.dispatch(setPillowInfoInState({ pillows: newArrPillows }));
        break;
      }

      case TYPE_REDUCER.CHANGE_THREEKIT_CONFIGURATION: {
        const storeCopy = JSON.parse(JSON.stringify(store.getState()));
        let objPayload = action.payload;
        let objUpdatedAttributes: objSetConfigurationT = {};
        const userType = getUserType(storeCopy);
        const effectiveAccountId = getEffectiveAccountId(storeCopy);
        const activePillow = getThreekitActivePillow(store.getState());
        const pillows: any = getPillows(store.getState());
        const isSectional =
          isSectionalConfigurator() || isSectionConfiguratorUI(storeCopy);

        store.dispatch(changeLoadPlayerState(true));
        // додає правильний лоадер для звичайних диванів
        if (objPayload['item_type']) {
          const valuesCurrent =
            getActiveParamFromIdComponentUI('item_type')(storeCopy);
          if (objPayload['item_type'] !== valuesCurrent) {
            store.dispatch(changeLoadInit3kitState(true));
            window.player.camera.frameBoundingSphere();
          } else {
            // setTimeout(() => {
            // store.dispatch(changeLoadInit3kitState(false));
            // }, 3000);
          }
          window.player.on('rendered', () => {
            store.dispatch(changeLoadInit3kitState(false));
            window.player.camera.frameBoundingSphere();
          });
        }

        Object.keys(objPayload).forEach((objKeyParamUI: string) => {
          const idValueParamUI = objKeyParamUI;
          const valueParamUI = objPayload[objKeyParamUI];
          const objForUpdateThreekit = getObjForUpdateThreekit(
            storeCopy,
            idValueParamUI,
            valueParamUI
          );

          objUpdatedAttributes = {
            ...objUpdatedAttributes,
            ...objForUpdateThreekit,
          };

          if (idValueParamUI === 'pillows') {
            const valuesCurrent =
              getActiveParamFromIdComponentUI(idValueParamUI)(storeCopy);
            objPayload = {
              ...objPayload,
              [idValueParamUI]: [...valuesCurrent, valueParamUI],
            };
          }

          if (idValueParamUI === 'ArmSelectorWidth') {
            const idValueParamUIArmSelector = 'style';
            const valueArmSelector = getActiveParamFromIdComponentUI(
              idValueParamUIArmSelector
            )(storeCopy);
            if (valueArmSelector !== null) {
              let arrValuesArmSelector =
                getOptionsArrForArmSelectorByArmSelectorWidth(storeCopy)(
                  idValueParamUIArmSelector,
                  valueParamUI
                );
              const newValueArmSelector = getNewValueForArmSelector(
                arrValuesArmSelector,
                valueArmSelector
              );

              const objForUpdateThreekit = getObjForUpdateThreekit(
                storeCopy,
                idValueParamUIArmSelector,
                newValueArmSelector
              );

              objUpdatedAttributes = {
                ...objUpdatedAttributes,
                ...objForUpdateThreekit,
              };
              objPayload = {
                ...objPayload,
                [idValueParamUIArmSelector]: newValueArmSelector,
              };
            }
          }

          if (idValueParamUI === 'storage') {
            let storageValue: any;
            if (valueParamUI === 'No Drawer') {
              storageValue = false;
            } else if (valueParamUI === 'End Drawer') {
              storageValue = true;
            } else {
              storageValue = false;
            }

            objUpdatedAttributes = {
              ...objUpdatedAttributes,
              Storage: storageValue,
            };
          }
        });

        if (isSectional) {
          let listModels: any[] = getThreekitModels(storeCopy);
          if (objPayload['PillowMaterial']) {
            let pillow: any = { id: window.player.selectionSet.ids[0] };

            if (!pillow.id.includes('Pillows_')) {
              pillow = pillows.find((p: any) =>
                p.id.includes(
                  activePillow
                    .replace('_', ' ')
                    .replace('_low', '')
                    .replace('_Pillow', ' Pillow')
                )
              );
            }

            if (pillow) {
              store.dispatch(
                changeMaterialPillow({
                  id: pillow.id,
                  material: objPayload['PillowMaterial'],
                })
              );
              listModels = [{ id: pillow.id }];
            }
          }

          if (objUpdatedAttributes && objUpdatedAttributes.setPillows) {
            //@ts-ignore
            const setPillows = objUpdatedAttributes.setPillows.at(-1);
            const assetId = setPillows.assetId;
            let xTranslation = listModels[0].transform.translation.x;
            if (listModels[0].type === 'Corner') {
              xTranslation = listModels[0].transform.translation.x - 0.2;
            }
            const transition = {
              ...listModels[0].transform.translation,
              x: xTranslation,
              y: listModels[0].transform.translation.y + PILLOW_POSITION_Y,
            };
            const rotation = {
              x: 0,
              y: 0,
              z: 0,
            };

            const id = addNewNodePillow(
              `Pillows_${objPayload['pillows'].at(-1)}_${Math.random() * 100}`,
              transition,
              rotation,
              assetId
            );
            const name = objPayload['pillows'].at(-1);

            const addNewPillow = async () => {
              const defaultMaterial: any = await getPillowMaterialInModel(
                listModels[0].id
              );

              const itemMaterial = defaultMaterial[1].values.find(
                (item: any) => item.name === defaultMaterial[0]
              );
              // const valuesFromIdAttribute = getValuesFromIdAttribute(
              //   idParameterUILegsMaterial
              // )(storeCopy);

              let sku = itemMaterial.metadata.sku
                ? itemMaterial.metadata.sku
                : undefined;

              const pillowPrice: any = await getPricePillow({
                pillows: name,
                fabric: defaultMaterial[0],
                sku,
                userType,
                effectiveAccountId,
              });

              store.dispatch(
                addPillowInState({
                  id,
                  name: name,
                  material: defaultMaterial[0],
                  price: pillowPrice.price,
                  skuFull: pillowPrice.skuFull,
                  skuMaterial: sku,
                  skuShort: pillowPrice.skuShort,
                  defaultPillowMaterial: defaultMaterial[0],
                  modelInScene: true,
                  assetId,
                })
              );
            };
            addNewPillow();
          }
          // item_type
          updateMaterialInModel(listModels)({ ...objUpdatedAttributes }).then(
            async () => {
              // if (
              //   THREEKIT_PARAMS.threekitUrl === "https://admin-fts.threekit.com/"
              // )
              //   updateMaterialInModel(listModels)({ ...objUpdatedAttributes });

              store.dispatch(changeLoadPlayerState(false));

              if (objPayload['depth']) {
                // const actualModelObg = getActualboundingBoxInAllModel()
                // Перерахунок координат (знайти список моделей в функцію, функція повертає список нових моделей і плюсів )
                // оноволення координат моделей і плюсів
                // запускається редюс який записує оновленні дані в стейт
                // в стейті міняється: boundybox, segmentsModel, transform, transition
              }

              if (Object.keys(objPayload).includes('leg')) {
                const materilaModels = await getObjectUniqueMaterialModels(
                  storeCopy
                );

                store.dispatch(
                  setAllThreekitAtributes({
                    arrAllThreekitAtributes: materilaModels,
                  })
                );
                const nameAttribiteLegsMaterial = 'Legs material';
                const idParameterUILegsMaterial = 'leg_material';
                const storeCopyNew = JSON.parse(
                  JSON.stringify(store.getState())
                );
                const valuesFromIdAttribute = getValuesFromIdAttribute(
                  idParameterUILegsMaterial
                )(storeCopyNew);
                const firstValueLegsMaterial = valuesFromIdAttribute[0]['name'];
                const objAssetIdForValue = getObjAssetIdFromThreekit(
                  storeCopyNew
                )(nameAttribiteLegsMaterial, firstValueLegsMaterial);

                updateMaterialInModel(listModels)({
                  [nameAttribiteLegsMaterial]: objAssetIdForValue,
                }).then(async () => {
                  store.dispatch(changeLoadPlayerState(false));
                  store.dispatch(
                    setActiveParamsUI({
                      [idParameterUILegsMaterial]: firstValueLegsMaterial,
                    })
                  );
                });
              }
            }
          );
        }

        const addInfoPillows = async () => {
          const checkAddPillow =
            objUpdatedAttributes && objUpdatedAttributes.setPillows;
          // debugger
          if (checkAddPillow) {
            //@ts-ignore
            const setPillows = objUpdatedAttributes.setPillows.at(-1);

            const assetId = setPillows.assetId;
            const transition = {
              x: 0,
              y: PILLOW_POSITION_Y,
              z: 0,
            };
            let { id, indexActivePillow } = getInfoPillowByLastAssetId(assetId);
            const namePillow = objPayload.pillows[0];
            const numberMaxPillowCurentSofaRelativeThreekit =
              getMaxPillowCurentSofaRelativeThreekit();

            const isMaxPillowInSofa =
              indexActivePillow >= numberMaxPillowCurentSofaRelativeThreekit &&
              !isSectionalConfigurator();

            let material: any;
            let activeMaterialInfo;
            let defaultPillowMaterialName;
            let materialObg = {};
            let sku = undefined;
            if (isMaxPillowInSofa) {
              //@ts-ignore
              defaultPillowMaterialName = pillows[0].defaultPillowMaterial;
              sku = pillows[0].skuMaterial;
            } else {
              const idPillowMaterial = 'PillowMaterial';
              const storeCopyNew = JSON.parse(JSON.stringify(store.getState()));

              const valuesFromIdAttribute =
                getValuesFromIdAttribute(idPillowMaterial)(storeCopyNew);
              material = await getPillowMaterialInModel(id);
              activeMaterialInfo = material[1].values.find(
                (item: any) => item.name === material[0]
              );
              defaultPillowMaterialName = material[0];
              materialObg = {
                'Pillow material': {
                  assetId: activeMaterialInfo.assetId,
                },
              };
              let itemMaterial = material[1].values.find(
                (item: any) => item.name === material[0]
              );
              // const valuesFromIdAttribute = getValuesFromIdAttribute(
              //   idParameterUILegsMaterial
              // )(storeCopy);

              sku = itemMaterial.metadata.sku
                ? itemMaterial.metadata.sku
                : undefined;
            }

            const pillowPrice: any = await getPricePillow({
              pillows: namePillow,
              fabric: defaultPillowMaterialName,
              sku,
              userType,
              effectiveAccountId,
            });
            let modelInScene = true;
            if (isMaxPillowInSofa) {
              modelInScene = false;
              id = `noId ${indexActivePillow}`;
            }

            store.dispatch(
              addPillowInState({
                id,
                transition,
                name: namePillow,
                materialObg,
                indexPillow: indexActivePillow,
                price: pillowPrice.price,
                skuMaterial: sku,
                skuFull: pillowPrice.skuFull,
                skuShort: pillowPrice.skuShort,
                material: defaultPillowMaterialName,
                defaultPillowMaterial: defaultPillowMaterialName,
                modelInScene: modelInScene,
                assetId,
              })
            );
            // store.dispatch(deletePillowInStateById({ id }));
          }
        };

        if (objPayload['PillowMaterial']) {
          store.dispatch(changeLoadPlayerState(true));
          updateAllPillowsMaterial({ objUpdatedAttributes });
        } else {
          setUpdateAttributes(objUpdatedAttributes).then(async (res) => {
            if (Object.keys(objPayload).includes('leg') && !isSectional) {
              store.dispatch(
                setAllThreekitAtributes({
                  arrAllThreekitAtributes: getDisplayAttributes(),
                })
              );

              const nameAttribiteLegsMaterial = 'Legs material';
              const idParameterUILegsMaterial = 'leg_material';
              const storeCopyNew = JSON.parse(JSON.stringify(store.getState()));
              const valuesFromIdAttribute = getValuesFromIdAttribute(
                idParameterUILegsMaterial
              )(storeCopyNew);
              const firstValueLegsMaterial = valuesFromIdAttribute[0]['name'];
              const objAssetIdForValue = getObjAssetIdFromThreekit(
                storeCopyNew
              )(nameAttribiteLegsMaterial, firstValueLegsMaterial);

              // const prevValueLegsMaterial = getActiveParamFromIdComponentUI(
              //   idParameterUILegsMaterial
              // )(storeCopy);
              // let isAnActualMaterial =
              //   prevValueLegsMaterial.split(" ")[0] !==
              //   firstValueLegsMaterial.split(" ")[0];

              setUpdateAttribute(
                nameAttribiteLegsMaterial,
                objAssetIdForValue
              ).then(() => {
                store.dispatch(
                  setActiveParamsUI({
                    [idParameterUILegsMaterial]: firstValueLegsMaterial,
                  })
                );
              });
            }

            const activeCollection = getActiveCollectionId(storeCopy);
            if (!isSectional) {
              const actualAttr = window.configurator.getDisplayAttributes();
              store.dispatch(
                setAllThreekitAtributes({ arrAllThreekitAtributes: actualAttr })
              );
              addInfoPillows();
              setTimeout(() => {
                // updateAllPillowsMaterial({objUpdatedAttributes})
                store.dispatch(changeLoadInit3kitState(false));
              }, 2000);
            } else {
              store.dispatch(changeLoadInit3kitState(false));
            }

            if (!isSectional) {
              store.dispatch(changeLoadPlayerState(false));
              // store.dispatch(changeLoadInit3kitState(false));
            }
          });
        }

        store.dispatch(setActiveParamsUI(objPayload));
        store.dispatch(updateModelsPrice());

        break;
      }
      case TYPE_REDUCER.SET_PRESET_MODELS_AND_PARAMS: {
        const { models, activeParamsThreekit } = action.payload;
        const state = store.getState();

        const updateMaterialsForPreset = () => {
          getObjectUniqueMaterialModels(state).then((materilaModels) => {
            store.dispatch(
              setAllThreekitAtributes({
                arrAllThreekitAtributes: materilaModels,
              })
            );

            const objActiveValuesThreekit =
              getObjForUpdateThreekitFromNameParams(
                materilaModels,
                activeParamsThreekit
              );
            const defaultValues = getThreekitDefaultValues(materilaModels);
            const activeMaterials: any = getThreekitObjMaterials(
              store.getState()
            );
            const updatedMaterialsForModels = !!activeParamsThreekit
              ? { ...defaultValues, ...objActiveValuesThreekit }
              : activeMaterials;

            updateMaterialInModel(Object.values(models))(
              updatedMaterialsForModels
            ).then((res: any) => {
              store.dispatch(updateModelsPrice());
              store.dispatch(changeLoadPlayerState(false));
              setAllPlane();
              changeArttributeForUpdateScene();
              setCameraPLayerInCenter();
            });
          });
        };

        if (
          !!activeParamsThreekit &&
          Object.keys(activeParamsThreekit).includes('Legs selector')
        ) {
          const objAssetIdForValue = getValueFromThreekit(state)(
            'Legs selector',
            activeParamsThreekit['Legs selector']
          );

          setUpdateAttribute('Legs selector', objAssetIdForValue).then(() => {
            updateMaterialsForPreset();
          });
        } else {
          updateMaterialsForPreset();
        }

        if (!!activeParamsThreekit) {
          const objActiveParamsUI =
            getObjActiveParamsUIFromObjActiveParamsThreekit(state)(
              activeParamsThreekit
            );

          store.dispatch(setActiveParamsUI(objActiveParamsUI));
        }
        break;
      }

      case TYPE_REDUCER.UPDATE_ALL_PILLOW_PRICE: {
        const storeCopy = JSON.parse(JSON.stringify(store.getState()));
        const pillows = getPillows(storeCopy);
        const userType = getUserType(storeCopy);
        const effectiveAccountId = getEffectiveAccountId(storeCopy);

        pillows.map(async (item: any) => {
          const pillowPrice: any = await getPricePillow({
            pillows: item.name,
            fabric: item.material,
            sku: item.skuMaterial,
            userType,
            effectiveAccountId,
          });

          store.dispatch(
            updateThreeekitPillow({
              id: item.id,
              newAttributes: {
                price: pillowPrice.price,
              },
            })
          );
        });

        break;
      }
      case TYPE_REDUCER.UPDATE_MODELS_PRICE: {
        const storeCopy = JSON.parse(JSON.stringify(store.getState()));
        const objActiveParams = getObjActiveParamsUI(storeCopy);
        const activeCollection = getActiveCollectionId(storeCopy);
        const userType = getUserType(storeCopy);
        const effectiveAccountId = getEffectiveAccountId(storeCopy);
        let requestData: any = getParamsForApiPriceInfo(
          activeCollection,
          objActiveParams
        );

        const instanceApi = instanceApiFunc({
          authToken: process.env.REACT_APP_API_TOKEN || '',
          urlApi: process.env.REACT_APP_API_URL || '/api',
        });
        let sku = undefined;
        if (window.configurator) {
          const sceneAttributes = getDisplayAttributes();
          const materialModels = sceneAttributes.find(
            (item: any) => item.name === 'Materials'
          );
          const modelItem = materialModels.values.find(
            (el: any) => el.name === objActiveParams.fabric
          );
          sku = modelItem.metadata.sku ? modelItem.metadata.sku : undefined;
        }

        if (
          requestData.category === 'Bed' ||
          requestData.category === 'Heardboard'
        ) {
          const storage =
            objActiveParams.activeTab === 'Headboards Only'
              ? 'No Drawer'
              : objActiveParams.storage;

          requestData = {
            ...requestData,
            style: `${requestData.style} ${storage}`,
          };
        }

        requestData = {
          ...requestData,
          userType,
          effectiveAccountId,
          sku,
        };

        const category = requestData.category;
        const lengthData: any = {
          Sofa: 12,
          Sectional: 12,
          Ottoman: 6,
          Chair: 6,
          Bed: 14,
          Headboard: 14,
        };
        const keys = Object.keys(requestData);
        if (keys.length === lengthData[category]) {
          updatePriceInfoModels(instanceApi, requestData)
            .then((resultUpdate: PricesAndSkuResponseApiT) => {
              const hidePrice = resultUpdate.hidePrice;
              store.dispatch(updateInfoHidePrice(hidePrice));
              store.dispatch(updateInfoModelPrice(resultUpdate['data']));
            })
            .catch((error) => {
              console.error('error --- ==== ', error);
            });
          dimentionsSetup();
        }

        break;
      }
    }

    let result = next(action);

    switch (action.type) {
      case TYPE_REDUCER.ADD_ACTIVE_POINT: {
        const { id } = action.payload;
        const state = store.getState();
        const userModel = getActiveUserModel(state);
        // store.dispatch(setModel({ assetId: userModel }));
        break;
      }
      case TYPE_REDUCER.SET_LIST_MODEL_IN_REDAX:
      // case TYPE_REDUCER.ADD_ACTIVE_POINT:
      case TYPE_REDUCER.SET_UPDATE_POINTS_OR_MODELS:
      case TYPE_REDUCER.SET_DELETE_POINTS_OR_MODELS:
      case TYPE_REDUCER.SET_NEW_POINTS: {
        const state = store.getState();
        const points = getListNewPoint(state);
        const activeCategory = getActiveCategory(state);

        // if (activeCategory === "accordionAdditionalSections") {
        //   if (Object.keys(points).length > 1) {
        //     pointsShow["showPoint"].forEach((point) => {
        //       setModelVisibility(point, true);
        //     });
        //     pointsShow["listNotVisible"].forEach((point) => {
        //       setModelVisibility(point, true);
        //     });
        //   }
        // } else {
        //   if (Object.keys(points).length > 1) {
        //     pointsShow["showPoint"].forEach((point) => {
        //       setModelVisibility(point, true);
        //     });
        //     pointsShow["listNotVisible"].forEach((point) => {
        //       setModelVisibility(point, false);
        //     });
        //   }
        // }

        if (isSectionalConfigurator()) {
          const timerAnnotations = setInterval(() => {
            const HTMLPoints = document.getElementsByClassName('point');
            const annotations = window.playerT.getAnnotations();
            if (annotations.length > 0) {
              clearInterval(timerAnnotations);
              Array.from(HTMLPoints).forEach((HTMLPoint) => {
                const findAnnotation = annotations.find(
                  (annotation: any) => annotation.id === HTMLPoint.id
                );

                if (!findAnnotation) {
                  HTMLPoint.setAttribute('style', 'display: none !important;');
                  HTMLPoint.id = '';
                  HTMLPoint.className = '';
                }
              });
            }
          });
        }

        if (
          [
            TYPE_REDUCER.SET_LIST_MODEL_IN_REDAX,
            TYPE_REDUCER.SET_UPDATE_POINTS_OR_MODELS,
            TYPE_REDUCER.SET_DELETE_POINTS_OR_MODELS,
          ].includes(action.type)
        ) {
          const updatePointsOrModels = async () => {
            let pointsShow = await getPointsShow(state);

            if (pointsShow['showPoint'].length === 0) {
              store.dispatch(completeBuild({ status: true }));
            } else {
              store.dispatch(completeBuild({ status: false }));
            }

            setAllPlane();
            getObjectUniqueMaterialModels(state).then((materilaModels) => {
              store.dispatch(
                setAllThreekitAtributes({
                  arrAllThreekitAtributes: materilaModels,
                })
              );

              setCameraPLayerInCenter();
            });

            getAllPillowsMaterials(state).then((allPillowMaterials) => {
              store.dispatch(
                setAllMaterialsForPillows({
                  allMaterials: allPillowMaterials,
                })
              );
            });

            const activeMaterials: any = getThreekitObjMaterials(state);
            const listModels = getThreekitModels(state);

            // updateMaterialInModel(listModels)(activeMaterials).then(() => {
            store.dispatch(updateModelsPrice());
            store.dispatch(changeLoadPlayerState(false));

            // changeArttributeForUpdateScene();
            // });
          };
          updatePointsOrModels();
        }

        break;
      }
    }
    return result;
  };

//   switch (action.type) {
//     case TYPE_REDUCER.SET_LIST_MODEL_IN_REDAX:
//     case TYPE_REDUCER.ADD_ACTIVE_POINT:
//     case TYPE_REDUCER.SET_UPDATE_POINTS_OR_MODELS:
//     case TYPE_REDUCER.SET_DELETE_POINTS_OR_MODELS:
//     case TYPE_REDUCER.SET_NEW_POINTS:
//       const state = store.getState();
//       const points = getListNewPoint(state);

//       if (Object.keys(points).length > 1) {
//         const pointsShow = getPointsShow(state);
//         if (pointsShow['showPoint'].length === 0) {
//           store.dispatch(completeBuild());
//         }

//         pointsShow['showPoint'].forEach((point) => {
//           setModelVisibility(point, true);
//         });
//         pointsShow['listNotVisible'].forEach((point) => {
//           setModelVisibility(point, false);
//         });
//       }

//       break;
//   }
//   return result;
// };
