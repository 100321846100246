import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import GesturesTutorial from "../../components/Modals/GesturesTutorial/GesturesTutorial";
import ModalNotValidMesagge from "../../components/Modals/NotValidMesagge/NotValidMesagge";
import ModalPrintInfo from "../../components/Modals/PrintInfo/PrintInfo";
import ModalRefreshConfiguratorMesagge from "../../components/Modals/RefreshConfiguratorMesagge/RefreshConfiguratorMesagge";
import ModalSaveConfig from "../../components/Modals/SaveConfig/SaveConfig";
import ModalSectionalTutorial from "../../components/Modals/SectionalTutorial/SectionalTutorial";
import { CustomizedState } from "../../components/Player/Player";
import { getShortId } from "../../function/base/getShortId";
import { openCloseModals } from "../../redux/features/ui/uiSlice";
import { getPillows } from "../../redux/selectors/threekit.selectors";
import {
  getAllThreekitAtribute,
  getObjActiveParamsUI,
} from "../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import SectionalConfigurator from "../SectionalConfigurator/SectionalConfigurator";
import SingleConfigurator from "../SingleConfigurator/SingleConfigurator";
import { getActualLinkForSaveConfigurator } from "../../utils/supportUtils";
import ModalPillowMoveHint from "../../components/Modals/PillowMoveHint/PillowMoveHint";
import MailSent from "../../components/Modals/MailSent/MailSent";

export default function LayoutConfigurator() {
  const { hash, key, pathname, search, state } = useLocation();
  const locationState = useLocation().state as CustomizedState;
  const collectionId = !!locationState
    ? locationState.collectionId
    : pathname.split("/")[1];
  const dispatch = useAppDispatch();

  const [lastAction, setLastAction] = useState(Date.now());

  const objActiveParamsUI = useAppSelector((state) =>
    getObjActiveParamsUI(state)
  );
  const allThreekitAtribute = useAppSelector((state) =>
    getAllThreekitAtribute(state)
  );
  const threeKit = useAppSelector((state) => state.threeKit);
  const pillows = useAppSelector((state) => getPillows(state));
  const modelsUI = useAppSelector((state) => state.ui.models);

  const handleScroll = () => {
    setLastAction(Date.now());
  };

  const handleClick = () => {
    setLastAction(Date.now());
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastAction > 3600000) {
        clearInterval(intervalId);
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("click", handleClick);
        getShortId({
          allThreekitAtribute,
          objActiveParamsUI,
          modelsUI,
          pillows,
          threeKit,
        }).then((shortId: any) => {
          const url = getActualLinkForSaveConfigurator();

          const linkText = `${url}?shortId=${shortId[0]}`;

          dispatch(
            openCloseModals({
              name: "RefreshConfiguratorMesagge",
              data: { linkText: linkText },
            })
          );
        });
      }
    }, 60000); // проверяем каждую минуту

    // Отслеживаем прокрутку страницы
    window.addEventListener("scroll", handleScroll);

    // Отслеживаем клики по странице
    window.addEventListener("click", handleClick);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleClick);
    };
  }, [lastAction]);

  return (
    <>
      {collectionId === "sectional" ? (
        <>
          <SectionalConfigurator />
          <ModalSectionalTutorial />
        </>
      ) : (
        <SingleConfigurator />
      )}
      <ModalSaveConfig />
      <GesturesTutorial />
      <ModalPrintInfo />
      <ModalNotValidMesagge />
      <ModalRefreshConfiguratorMesagge />
      <ModalPillowMoveHint />
      <Outlet />
      <MailSent />
    </>
  );
}
