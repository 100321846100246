import { additionalSections } from "./Constants/additionalSections";
import { armSilhouette } from "./Constants/armSilhouette";

export const TYPE_REDUCER = {
  SET_NEW_POINTS: "[points] :: set new Points",
  SET_UPDATE_POINTS_OR_MODELS: "[points] :: set update points or models",
  SET_DELETE_POINTS_OR_MODELS: "[points] :: set delete points or models",
  SET_USER_MODEL: "[points] :: set user active model",

  // SET_START_MODEL: "[player] :: add first model in threekit",
  SET_START_MODEL_OBJ: "[player] :: add first model in threekit NEW",
  SET_MODEL: "[player] :: add model in threekit",
  SET_LIST_MODEL_IN_REDAX: "[player] :: set list model redUx",

  ADD_ACTIVE_POINT: "[player] :: add active point",
  ADD_ACTIVE_MODEL: "[player] :: add active model",
  SET_ACTIVE_MODEL_IN_STATE: "[player] ::: set active model state",
  ADD_ALL_MODELS: "[player] :: add all models",
  DELETE_MODEL: "[player] :: delete model",
  DELETE_ALL_MODELS: "[player] :: delete all models",
  ADD_PILLOW_IN_STATE: "[player] :: add pillow in state",
  ADD_DELETE_IN_STATE: "[player] :: delete pillow in state",
  ADD_ACTIVE_PILLOW: "[player] :: add active pillow",
  CHANGE_MATERIAL_PILLOW: "[player] :: change material pillow",

  ADD_NEW_THREEKIT_STATE: "[player] :: add new state",

  UPDATE_STORE: "[player] :: update store",
  SET_INIT_STATE: "[player] :: set init state",

  CHANGE_BULDING_STATUS: "[player] :: change bulding player",
  UPDATE_PILLOW_MATERIALS: "[player] ::: update pillow materials",
  SET_ALL_MATERIALS: "[PLAYER] :: set all materials",
  // SET_MODEL_FOR_SINGLE_CONFIG: "[player] :: set model for single config",
  CHANGE_THREEKIT_CONFIGURATION: "[player] :: change threekit configuration",
  SET_PRESET_MODELS_AND_PARAMS:
    "[player] :: set preset models and params threekit",
  DELETE_PILLOW: "[player] :: delete pillow",
  DELETE_PILLOW_STATE: "[player] :: delete pillows in threekit state",
  SET_PILLOW_INFO_IN_STATE: "[player] :: set pillows infos in threekit state",
  DELETE_ALL_PILLOWS: "[player] :: delete all pillows",
  DELETE_ALL_PILLOWS_IN_STATE: "[player] :: delete all pillows in state",
  UPDATE_PILLOW_IN_STATE: "[player] :: update pillows",
  UPDATE_MODELS_PRICE: "[prices] :: update models price",
  UPDATE_ALL_PILLOW_PRICE: "[prices] :: update pillows price",
};
export const THREEKIT_PARAMS = {
  threekitUrl: "https://admin-fts.threekit.com/",
  // authToken: "d79196bb-bd56-4af0-bc1f-31f5984f68c0",
  authToken: "a6867a75-94ca-445d-b719-d26ac81086a8", // prod
  // authToken: "fe0983b1-b956-4c7c-a095-4d509c81360d", // test
  
  orgId: "cea0d0fc-d205-4715-abfe-168ba66040d8",
  // orgId: "8e762fba-3556-4e54-8abf-9880e97c71e1",
  // scenes: {
  //   sectional: {
  //     authToken: "d79196bb-bd56-4af0-bc1f-31f5984f68c0",
  //     assetId: "99f34357-00e5-46dc-9280-3a482cb6f5a5",
  //     orgId: "8e762fba-3556-4e54-8abf-9880e97c71e1",
  //   },
  // },
};

export const PAGES = {
  index: "/",
  models: ":collection",
  config: "config",
};

export const cameraPositions = [
  {
    label: 'Front view',
    value: 'CameraA',
  },
  {
    label: 'Left view',
    value: 'CameraB',
  },
  {
    label: 'Back view',
    value: 'CameraC',
  },
  {
    label: 'Right view',
    value: 'CameraD',
  },
];

export const tabs = {
  sectional: [
    {
      value: "create",
      label: "Create",
    },
    {
      value: "presets",
      label: "Presets",
    },
    {
      value: "fabrics",
      label: "Fabrics",
    },
  ],
  classic: [
    {
      value: "create",
      label: "Create",
    },
  ],
};