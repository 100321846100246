import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import styled from "styled-components";

import "antd/lib/switch/style/index.css";
import s from "./FooterPlayer.module.scss";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  getActiveCamera,
  getActiveCollectionId,
  getActiveParamFromIdComponentUI,
  getActiveTab,
  getArrCameras,
} from "../../redux/selectors/ui.selector";
import { changeThreekitConfiguration } from "../../redux/actions/player.action";
import {
  cameraValuesT,
  objCameraItemT,
} from "../../redux/features/ui/ui.types";
import {
  changeActiveSide,
  openCloseModals,
} from "../../redux/features/ui/uiSlice";
import ButtonWithLabel from "../ButtonWithLabel/ButtonWithLabel";
import { isSectionalConfigurator } from "../../utils/supportUtils";

let StyledSelect: any = styled(Switch)` 
    &.ant-switch{
        background: rgba(61, 115, 141, 0.5) !important;
        height: 30px;
        min-width: 60px;
    } 
    &.ant-switch .ant-switch-handle{
        width: 26px;
        height: 26px;
    }
    &.ant-switch .ant-switch-handle::before{
        border-radius: 50%;
    }
    &.ant-switch-checked{
        background-color: #425D76 !important;
    }
    &.ant-switch-checked .ant-switch-handle{
        left: calc(100% - 26px - 2px);
    }
    &.ant-switch:focus {
        box-shadow: none !important;
    } 
    &.ant-switch-checked:focus{
        box-shadow: none !important;
    }
     &.ant-switch .ant-click-animating-node{
        box-shadow: none !important;
        -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1)  !important;
    }
    @keyframes waveEffect{
        100% {box-shadow: 0 0 0 6px #425d76; !important; }
    }
   
`;

export const openFullScreen = () => {
  const player = document.getElementById("playerContainer");
  const closeButton = document.getElementById("closeFull");

  if (player && closeButton) {
    setTimeout(() => {
      closeButton.style.cssText =
        "display: block !important; position: absolute; right: 50px; top: 50px; z-index: 999; cursor: pointer";
    }, 1000);

    const requestFullScreen = (
      player.requestFullscreen ||
      //@ts-ignore
      player.mozRequestFullScreen ||
      //@ts-ignore
      player.webkitRequestFullScreen ||
      //@ts-ignore
      player.msRequestFullscreen
    ).bind(player);

    if (requestFullScreen) {
      requestFullScreen().catch((error) => {
        console.error("Failed to enter fullscreen mode:", error);
      });
    } else {
      console.error("Fullscreen API is not supported by this browser");
    }

    window.player.scene.set(
      {
        name: "testSceneVST",
        plug: "Environment",
        property: "backgroundColorOpacity",
      },
      1
    );
  } else {
    console.error("Player or closeButton not found");
  }
};
export const FooterPlayer = () => {
  // const [dimensionsIsActive, setDimensionsIsActive] = useState(false);
  // const [camera, setCamera] = useState(cameraPositions[0].id);
  // const [isSwitchDimentions, setSwitchDimentions] = useState(false);

  const dispatch = useAppDispatch();
  const valueShowDimentions = useAppSelector(
    getActiveParamFromIdComponentUI("showDimentions")
  );
  const camerasList = useAppSelector((state) => getArrCameras(state));
  const activeCamera = useAppSelector((state) => getActiveCamera(state));
  const activeTab = useAppSelector((state) => getActiveTab(state));

  let activeCollectionId = useAppSelector((state) =>
    getActiveCollectionId(state)
  );
  const valueOpenDrawer = useAppSelector(
    getActiveParamFromIdComponentUI("openDrawer")
  );
  const valueStorage = useAppSelector(
    getActiveParamFromIdComponentUI("storage")
  );

  const changeShowDimmentions = (value: boolean) => {
    // setSwitchDimentions(value);
    dispatch(changeThreekitConfiguration({ ["showDimentions"]: value }));
  };

  const OpenTutorial = (): void => {
    dispatch(openCloseModals({ name: "SectionalTutorial" }));
  };
  const OpenGestures = (): void => {
    dispatch(openCloseModals({ name: "GesturesTutorial" }));
  };

  const changeBedDriwerState = (value: boolean) => {
    if (value === valueOpenDrawer) return;
    dispatch(changeThreekitConfiguration({ ["openDrawer"]: value }));
  };

  const changeCamera = (value: cameraValuesT) => {
    // setCamera(value);
    dispatch(changeActiveSide({ side: value }));
  };

  const setNextCamera = () => {
    const indexCurrentCamera = camerasList.findIndex(
      (objCamera: objCameraItemT, index) => {
        if (objCamera["value"] === activeCamera) return true;
      }
    );
    const newIndexActiveCamera =
      indexCurrentCamera < camerasList.length - 1 ? indexCurrentCamera + 1 : 0;
    dispatch(
      changeActiveSide({ side: camerasList[newIndexActiveCamera]["value"] })
    );
  };

  const isBedCollection = activeTab === "Beds";
  const isDrawer = valueStorage === "End Drawer";

  return (
    <div className={s.player_interface}>
      <div className={s.checkbox__block}>
        {/* <StyledSelect
          checked={valueShowDimentions}
          onChange={(value: boolean) => changeShowDimmentions(value)}
        />
        <div className={s.checkbox_title}>
          Show <span>furniture</span> dimensions
        </div> */}
      </div>
      <div className={s.camera__block}>
        <div className={s.camera_rotate}>
          {" "}
          <img
            src="/images/assets/svg/RotateCamera.svg"
            alt=""
          />
          <p>Click & Drag to Rotate 360°</p>
        </div>
        {/* {camerasList &&
          camerasList.map((item: objCameraItemT, index) => {
            const isActive = activeCamera === item.value ? s.active : "";
            return (
              <div
                className={`${s.camera_item} ${isActive}`}
                onClick={() => changeCamera(item.value)}
                key={`${item.value}-${index}`}
              >
                {item.label}
              </div>
            );
          })} */}
        {/* <div className={s.camera_mob}>
          <ChangeCamera />
        </div> */}
      </div>
      <div className={s.right}>
        <div className={s.rotateTutorial}>
          <ButtonWithLabel
            label="Gestures"
            icon={
              <img
                src="/images/assets/tutorial/RotateCamera.svg"
                alt=""
              />
            }
            onClick={() => OpenGestures()}
          />
        </div>
        {isSectionalConfigurator() && (
          <ButtonWithLabel
            label="How to use"
            icon={
              <img
                src="/images/assets/svg/UseBook.svg"
                alt=""
              />
            }
            onClick={() => OpenTutorial()}
          />
        )}
        <ButtonWithLabel
          label="Dimensions"
          icon={
            <img
              src="/images/assets/svg/Dimenstions.svg"
              alt=""
            />
          }
          onClick={() => changeShowDimmentions(!valueShowDimentions)}
        />
        <span className={s.FullWindow}>
          <ButtonWithLabel
            label="Full window"
            icon={
              <img
                src="/images/assets/svg/FullScreen.svg"
                alt=""
              />
            }
            onClick={() => openFullScreen()}
          />
        </span>
      </div>
      {/* <div className={s.ar}>
        <ArSvg />
        <span>View in AR</span>
      </div> */}
      {isBedCollection && isDrawer && (
        <div className={s.bedDriverControl}>
          <button
            className={`${!valueOpenDrawer && s.active}`}
            onClick={() => changeBedDriwerState(false)}
          >
            Closed
          </button>
          <button
            className={`${valueOpenDrawer && s.active}`}
            onClick={() => changeBedDriwerState(true)}
          >
            Open
          </button>
        </div>
      )}
    </div>
  );
};