import { Field, ErrorMessage, Form } from "formik";

import { FieldArrayEmails } from "./FieldArrayEmails/FieldArrayEmails";

import s from "./ConfigForm.module.scss";
import { useState } from "react";
import { ErrorMessageEmailModal } from "./ErrorMessageEmailModal/ErrorMessageEmailModal";

export const ConfigForm = ({ sendText, errors }: any) => {
  return (
    <Form>
      <div className={s.fildsLine}>
        <div className={s.inputWrap}>
          <label>Configuration name</label>
          <div className={s.fieldCollectionName}>
            <Field
              type="text"
              name="name"
              placeholder="Enter configuration name"
            />
            <ErrorMessage name="name" />
          </div>
        </div>
        <div className={s.inputWrap}>
          <label>Email {<ErrorMessageEmailModal errors={errors} />}</label>

          <FieldArrayEmails sendText={sendText} name="email" />
        </div>
      </div>
    </Form>
  );
};
