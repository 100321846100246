// import axios from "axios";
// import instanceApi from "./instanceApi";

import { AxiosInstance } from "axios";
import { objectActiveParamsT } from "../redux/features/ui/ui.types";
import { getArrActiveModelsNames } from "../redux/selectors/model.selector";
import { isSectionConfiguratorUI } from "../redux/selectors/ui.selector";
import { store } from "../redux/store";
import { isSectionalConfigurator } from "../utils/supportUtils";
import { instanceApiT } from "./instanceApi";
import {
  arrKeysForBedFullSku,
  arrKeysForChairFullSku,
  arrKeysForOttomanFullSku,
  arrKeysForPillowsFullSku,
  arrKeysForSofaFullSku,
  KeysQuerySku,
  PricesAndSkuRequestDataT,
  PricesAndSkuResponseApiT,
} from "./types/typesModelsPriceInfo";

// if (process.env.NODE_ENV === "development") {
// urlApi = String(process.env.REACT_APP_DEV_API_URL);
// }

export const getParamsForCollection = (
  objActiveParams: objectActiveParamsT,
  arrKeysForSku: KeysQuerySku[]
): PricesAndSkuRequestDataT => {
  const state = store.getState();
  const isSectional =
    isSectionalConfigurator() || isSectionConfiguratorUI(state);

  let requestData = arrKeysForSku.reduce(
    (accumulator: PricesAndSkuRequestDataT, key: KeysQuerySku) => {
      let value = objActiveParams?.[key];

      if (key === "placeholder1") value = "Placeholder 1";

      if (key === "placeholder2") value = "Placeholder 2";

      if (key === "leg") {
        value = `${value} / ${objActiveParams["leg_material"]}`;
      }

      if (key === "chair") value = objActiveParams["item_type"];

      if (key === "ottoman") value = objActiveParams["item_type"];

      if (key === "style" && isSectional && !!!objActiveParams[key])
        value = "Track";

      if (key === "pillows") {
        value = objActiveParams["pillows"];
      }

      if (!!value) return { ...accumulator, [key]: value.trim() };

      return { ...accumulator };
    },
    {}
  );

  if (isSectional) {
    const activeModels = getArrActiveModelsNames(state);
    requestData = { ...requestData, item_type: activeModels.join(", ") };
  }

  requestData = {
    ...requestData,
    fabric: !!objActiveParams["fabric"]
      ? objActiveParams["fabric"].split("_").join(" ")
      : "",
  };

  return requestData;
};

export const getParamsForApiPriceInfo = (
  activeCollection: string,
  objActiveParams: objectActiveParamsT
): PricesAndSkuRequestDataT => {
  let requestData: PricesAndSkuRequestDataT = {
    category: activeCollection[0].toUpperCase() + activeCollection.slice(1),
  };
  switch (activeCollection) {
    case "sofa":
      const sofaRequestData1 = getParamsForCollection(
        objActiveParams,
        arrKeysForSofaFullSku
      );
      requestData = { ...sofaRequestData1, ...requestData };

      break;
    case "sectional":
      const sofaRequestData = getParamsForCollection(
        objActiveParams,
        arrKeysForSofaFullSku
      );
      requestData = { ...sofaRequestData, ...requestData };

      break;
    case "chair":
      const chairRequestData = getParamsForCollection(
        objActiveParams,
        arrKeysForChairFullSku
      );
      requestData = { ...chairRequestData, ...requestData };
      break;
    case "ottoman":
      const ottomanRequestData = getParamsForCollection(
        objActiveParams,
        arrKeysForOttomanFullSku
      );
      requestData = { ...ottomanRequestData, ...requestData };
      break;
    case "bed":
      const bedRequestData = getParamsForCollection(
        objActiveParams,
        arrKeysForBedFullSku
      );

      const categoryData: any = {
        Beds: "Bed",
        "Headboards Only": "Headboard",
      };
      const tabs = document.querySelectorAll(".interface_tab");
      let textContent: any = "";
      tabs.forEach((tab) => {
        const isActive = tab.getAttribute("data-activeTab");
        if (isActive === "true") {
          textContent = tab.textContent;
        }
      });
      requestData = {
        ...bedRequestData,
        ...requestData,
        category: categoryData[textContent],
        item_type: "Headboard",
        storage: objActiveParams.storage,
      };

      break;
    case "Pillow":
      const pillowRequestData = getParamsForCollection(
        objActiveParams,
        arrKeysForPillowsFullSku
      );
      requestData = { ...pillowRequestData, ...requestData };
      break;
    default:
      break;
  }

  return requestData;
};

export const updatePriceInfoModels = (
  instanceApi: AxiosInstance,
  fieldsList: objectActiveParamsT
): Promise<PricesAndSkuResponseApiT> => {
  const params = new URLSearchParams(Object.entries(fieldsList));
  return instanceApi
    .get(`/priceInfo`, { params })
    .then((response: any) => response.data)
    .then((data: any) => {
      return data;
    });
};

export const sendMailInfo = (
  instanceApi: AxiosInstance,
  fieldsList: objectActiveParamsT
): Promise<PricesAndSkuResponseApiT> => {
  const params = new URLSearchParams(Object.entries(fieldsList));
  return instanceApi
    .get(`/sendEmail`, { params })
    .then((response: any) => response.data)
    .then((data: any) => {
      return data;
    });
};

export const sendPDFInfo = (
  instanceApi: AxiosInstance,
  fieldsList: objectActiveParamsT
): Promise<PricesAndSkuResponseApiT> => {
  const params = new URLSearchParams(Object.entries(fieldsList));
  return instanceApi
    .post(`/generatePDF`, { params })
    .then((response: any) => response.data)
    .then((data: any) => {
      return data;
    });
};

export const getMaterialsInDB = (
  instanceApi: AxiosInstance
): Promise<PricesAndSkuResponseApiT> => {
  return instanceApi
    .get(`/getMaterials`)
    .then((response: any) => response.data)
    .then((data: any) => {
      return data;
    });
};
